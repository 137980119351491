import React, { useState } from "react";
import { Contract, Footer, Header, MotorBike } from "../../../components";
import { useScrollTop } from "../../../services/helpingFunc";
import './MotorBikeHire.css'

export default function MotorBikeHire() {
    useScrollTop()

    return (
        <>
            {/* ******************** Header ******************** */}
            <Header title={'Motor Bike'} />

            {/* ******************** Motor Bike ******************** */}
            <MotorBike />

            {/* ******************** Contract Section ******************** */}
            <Contract />

            {/* ******************** Footer ******************** */}
            <Footer />
        </>
    );
}
