import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  userData: null,
  remeberMe: false,
  accessToken: '',
  refreshToken: '',
  subscription: {},
  fcmToken: '',
  userLocation: {},
  subscriptionModel: false,
  getRreviews: null,
  forgetOtpRoute: false,
};

export const userDataSlice = createSlice({
  name: 'userDATA',
  initialState,
  reducers: {
    userData: (state, action) => {
      state.userData = action.payload;
    },
    SaveRememberMe: (state, action) => {
      state.remeberMe = action.payload;
    },
    accessToken: (state, action) => {
      state.accessToken = action.payload;
    },
    refreshToken: (state, action) => {
      state.refreshToken = action.payload;
    },
    subscription: (state, action) => {
      state.subscription = action.payload;
    },
    fcmToken: (state, action) => {
      state.fcmToken = action.payload;
    },
    userLocation: (state, action) => {
      state.userLocation = action.payload;
    },
    subscriptionModel: (state, action) => {
      state.subscriptionModel = action.payload;
    },
    SaveReviews: (state, action) => {
      state.getRreviews = action.payload;
    },
    SaveForgetOtpRoute: (state, action) => {
      state.forgetOtpRoute = action.payload;
    },
  },
});

export const { userData, accessToken, refreshToken, subscription, fcmToken, userLocation, subscriptionModel, SaveReviews, SaveRememberMe, SaveForgetOtpRoute } = userDataSlice.actions;

export default userDataSlice.reducer;
