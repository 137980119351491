import React, { useState } from "react";
import "./forgetPassword.css";
import { useNavigate } from "react-router-dom";
import { Button, Loader, TextInput } from "../../../components";
import { logo, authImage, HomeLogo } from "../../../assets/icons";
import { emailFormat } from "../../../services/validation";
import { notifyToast, useScrollTop } from "../../../services/helpingFunc";
import { api } from "../../../network/Environment";
import { callApi, Method } from "../../../network/NetworkManger";
import { useDispatch } from "react-redux";
import { SaveForgetOtpRoute } from "../../../redux/Slices/userDataSlice";

export default function ForgetPassword() {
    useScrollTop()

    const dispatch = useDispatch()
    const navigate = useNavigate();
    const [email, setEmail] = useState('')
    const [loading, setLoading] = useState(false);

    const handleMainButton = () => {
        if (email != '') {
            if (emailFormat.test(email.trim())) {
                setLoading(true);
                const onSuccess = response => {
                    dispatch(SaveForgetOtpRoute(true))
                    setLoading(false);
                    console.log("Forget Password Response: ", response);
                    notifyToast(response.message);
                    navigate('/OtpVerify', { state: { email: email, key: 'forgetPassword' } });
                };

                const onError = error => {
                    setLoading(false);
                    console.log("Forget Password error: ", error);
                    notifyToast(error?.message);
                };

                const endPoint = api.forgetPassword;
                const method = Method.POST;
                const bodyParams = {
                    email: email.toLowerCase(),
                };
                callApi(method, endPoint, bodyParams, onSuccess, onError);
            } else {
                notifyToast('Email is not valid!')
            }
        } else {
            notifyToast('Email is required!')
        }
    };

    return (
        <div className="forgetPassword-auth-container">
            <Loader loading={loading} />
            <div className="forgetPassword-scroll">
                <div onClick={() => navigate('/')}>
                    <img src={HomeLogo} alt='logo' className='forgetPassword-image_logo_style' />
                </div>
                <div className="forgetPassword-detail_view">
                    <h1>Forgot Password!</h1>
                    <h2>Enter your registered email address below to reset your password</h2>
                    <TextInput onChange={(e) => setEmail(e.target.value)} placeholder={'Enter Email Address'} title={'Email'} />
                    <div className="forgetPassword-button-view">
                        <Button onClick={() => handleMainButton()}>Continue</Button >
                    </div>
                </div>
            </div>
            <div className="forgetPassword-image_sideView">
                <img src={authImage} alt='authImage' className='forgetPassword-image_sideStyle' />
                <div className="image-caption">
                    <h3 className="image-title">Welcome</h3>
                    <p className="image-description">Musuwo Logistics</p>
                </div>
            </div>
        </div>
    );
}
