import React from 'react';
import { ourValuesImage, value1 } from '../../assets/icons';
import './ourValues.css'; // Make sure to create this CSS file

const OurValue = (props) => {
    return (
        <div className="value-container">
            <div className="value-left-section">
                <div>
                    <p>Our Values</p>
                    {/* <h1>Lorem ipsum dolor sit amet, conse ctetur </h1> */}
                    <div style={{ marginTop: '10px' }}>
                        <span style={{ fontFamily: 'montserratSemiBold', color: '#1E1E1E' }}> Reliability: <span>We understand the importance of dependable logistics. Our commitment to on-time deliveries and consistent service builds trust with every client.</span> </span>
                    </div>

                    <div>
                        <span style={{ fontFamily: 'montserratSemiBold', color: '#1E1E1E' }}> Innovation: <span>Embracing technology and innovation is at the heart of what we do. We continuously invest in the latest solutions to enhance efficiency and provide a superior logistics experience.</span> </span>
                    </div>

                    <div>
                        <span style={{ fontFamily: 'montserratSemiBold', color: '#1E1E1E' }}> Customer-Centric: <span>Your success is our priority. Our customer-centric approach ensures we meet and exceed your expectations, delivering customized solutions tailored to your needs.</span> </span>
                    </div>

                    <div>
                        <span style={{ fontFamily: 'montserratSemiBold', color: '#1E1E1E' }}> Excellence: <span> We believe in responsible logistics. Our eco-friendly initiatives and sustainable practices reflect our dedication to minimizing environmental impact.</span> </span>
                    </div>

                    <div>
                        <span style={{ fontFamily: 'montserratSemiBold', color: '#1E1E1E' }}> Teamwork: <span> we firmly believe that the power of collaboration is at the heart of our success. Teamwork is not just a value for us; it's a fundamental pillar that defines how we operate, innovate, and achieve our goals.</span> </span>
                    </div>

                    <div>
                        <span style={{ fontFamily: 'montserratSemiBold', color: '#1E1E1E' }}> Integrity: <span> We recognize that building trust is essential in today's dynamic business landscape and proudly uphold integrity as a guiding principle..</span> </span>
                    </div>
                </div>
            </div>
            <div className="value-right-section">
                <img src={value1} alt="values" />
            </div>
        </div>
    );
};

export default OurValue;
