import React, { useEffect } from 'react'
import './App.css'
import '../src/constant/fonts/index.css'
import 'react-toastify/dist/ReactToastify.css';
import { BrowserRouter, HashRouter } from "react-router-dom";
import SnackbarProvider from 'react-simple-snackbar'
import Navigation from './navigation/Navigation';
import { ToastContainer } from 'react-toastify';
import { GoogleOAuthProvider } from '@react-oauth/google';
import { GOOGLE_CLIENT_ID } from './constant';
import { accessToken, refreshToken, userData } from './redux/Slices/userDataSlice';
import { useDispatch, useSelector } from 'react-redux';

const App = () => {
  const dispatch = useDispatch()
  const remeberMe = useSelector((data) => data.userData.remeberMe);

  useEffect(() => {
    if (!remeberMe) {
      dispatch(userData(null))
      dispatch(accessToken(''))
      dispatch(refreshToken(''))
    }
  }, [])

  return (
    <GoogleOAuthProvider clientId={GOOGLE_CLIENT_ID}>
      <BrowserRouter>
        <SnackbarProvider>
          <Navigation />
        </SnackbarProvider>
        <ToastContainer />
      </BrowserRouter>
    </GoogleOAuthProvider>
  )
}

export default App
