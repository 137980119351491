import React from 'react';
import { Footer, Header } from '../../../components';
import './PrivacyPolicy.css'; // Import CSS file for styling

const PrivacyPolicy = () => {
    return (
        <>
            {/* ******************** Header ******************** */}
            <Header title={'Contact Us'} />

            <div className="privacy-policy-container">
                <div className="privacy-policy">
                    <h1>Privacy Policy</h1>
                    <p>Last updated: March 08, 2024</p>
                    <p>This Privacy Policy describes our policies and procedures regarding the collection, use, and disclosure of your information when you use the Service. It also tells you about your privacy rights and how the law protects You.</p>

                    <p>We use Your Personal data to provide and improve the Service. By using the Service, You agree to the collection and use of information in accordance with this Privacy Policy. This Privacy Policy has been created with the help of the Free Privacy Policy Generator.</p>

                    {/* Continue including the privacy policy content using <p> for paragraphs, <h2> for section headings, etc. */}

                    <h2>Interpretation and Definitions</h2>
                    <h3>Interpretation</h3>
                    <p>The words of which the initial letter is capitalized have meanings defined under the following conditions. The following definitions shall have the same meaning regardless of whether they appear in singular or in plural.</p>

                    <h3>Definitions</h3>
                    <p>For the purposes of this Privacy Policy:</p>
                    <ul style={{ marginLeft: '1%' }}>
                        <li><strong>Account</strong> means a unique account created for You to access our Service or parts of our Service.</li>
                        <li><strong>Affiliate</strong> means an entity that controls, is controlled by or is under common control with a party, where "control" means ownership of 50% or more of the shares, equity interest or other securities entitled to vote for election of directors or other managing authority.</li>
                        <li><strong>Application</strong> refers to Pick'tUp, the software program provided by the Company.</li>

                        <li><strong>Company</strong> (referred to as either "the Company", "We", "Us" or "Our" in this Agreement) refers to Musuwo logistics Pty Ltd, 29 Pine Road.</li>
                        <li><strong>Country</strong> refers to: South Africa</li>
                        <li><strong>Device</strong> means any device that can access the Service such as a computer, a cellphone or a digital tablet.</li>
                        <li><strong>Service Provider</strong> means any natural or legal person who processes the data on behalf of the Company. It refers to third-party companies or individuals employed by the Company to facilitate the Service, to provide the Service on behalf of the Company, to perform services related to the Service or to assist the Company in analyzing how the Service is used.</li>
                        <li><strong>Service</strong> refers to the Application.</li>
                        <li><strong>Usage Data</strong> refers to data collected automatically, either generated by the use of the Service or from the Service infrastructure itself (for example, the duration of a page visit).</li>
                        <li><strong>You</strong> means the individual accessing or using the Service, or the company, or other legal entity on behalf of which such individual is accessing or using the Service, as applicable.</li>
                    </ul>

                    <h3>Collecting and Using Your Personal Data</h3>
                    <h3>Types of Data Collected</h3>
                    <h3>Personal Data</h3>
                    <p>While using Our Service, We may ask You to provide Us with certain personally identifiable information that can be used to contact or identify You. Personally identifiable information may include, but is not limited to:</p>
                    <ul style={{ marginLeft: '1%' }}>
                        <li>Email address</li>
                        <li>First name and last name</li>
                        <li>Phone number</li>
                        <li>Address, State, Province, ZIP/Postal code, City</li>
                        <li>Usage Data</li>
                    </ul>

                    <h3>Usage Data</h3>
                    <p>Usage Data is collected automatically when using the Service.
                        Usage Data may include information such as Your Device's Internet Protocol address (e.g. IP address), browser type, browser version, the pages of our Service that You visit, the time and date of Your visit, the time spent on those pages, unique device identifiers and other diagnostic data.
                        When You access the Service by or through a mobile device, We may collect certain information automatically, including, but not limited to, the type of mobile device You use, Your mobile device unique ID, the IP address of Your mobile device, Your mobile operating system, the type of mobile Internet browser You use, unique device identifiers and other diagnostic data.
                        We may also collect information that Your browser sends whenever You visit our Service or when You access the Service by or through a mobile device.
                    </p>

                    <h3>Information Collected while Using the Application</h3>
                    <p>
                        While using Our Application, in order to provide features of Our Application, We may collect, with Your prior permission:
                        We use this information to provide features of Our Service, to improve and customize Our Service. The information may be uploaded to the Company's servers and/or a Service Provider's server or it may be simply stored on Your device.
                        You can enable or disable access to this information at any time, through Your Device settings.
                    </p>
                    {/* Include other sections similarly */}

                    <h2>Contact Us</h2>
                    <p>If you have any questions about this Privacy Policy, You can contact us:</p>
                    <ul style={{ marginLeft: '1%' }}>
                        <li>By email: support@musuwologistics.co.za</li>
                        <li>By phone number: 0110542528</li>
                        <li>Address: 41 Sloane Street, Bryanston, Johannesburg, 2191</li>
                    </ul>
                </div>
            </div>

            {/* ******************** Footer ******************** */}
            <Footer />
        </>


    );
};

export default PrivacyPolicy;
