import React from "react";
import { Footer, Header, LetEnjoy, MainSlider, Reviews, OurServices } from "../../../components";
import { useScrollTop } from "../../../services/helpingFunc";
import './home.css'

export default function Home() {
    useScrollTop()

    return (
        <>
            {/* ******************** Header ******************** */}
            <Header title={'Home'} />

            {/* ******************** Main Slider ******************** */}
            <MainSlider videURL={require('../../../assets/video/home_page.mp4')} title={'The world’s fastest Logistics Services.'} description={'Our same-day and seamless delivery service can be accessed through the Pick’tUp App. Experience the difference with our reliable courier services.'} />

            {/* ******************** Our Services ******************** */}
            <OurServices />

            {/* ******************** Reviews ******************** */}
            {/* <Reviews heading='Home' /> */}

            {/* ******************** Let's Enjoy ******************** */}
            <LetEnjoy />

            {/* ******************** Footer ******************** */}
            <Footer />
        </>
    );
}
