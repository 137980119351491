// ContainerComponent.jsx
import React from 'react';
import { androidStore, appleStore, HomeLogo, logo, iPhone } from '../../assets/icons';
import './Courier.css'; // Import the CSS file for styling

const Courier = () => {
    return (
        <div className="courier-main-container">
            <div className="courier-logo-container">
                <img src={HomeLogo} alt="Logo" />
            </div>
            <div className="courier-logo-title">Our Services</div>
            <div className="courier-title">Courier</div>
            <div className="courier-title-description">Our same-day and seamless delivery service can be accessed through the Pick’tUp App. Experience the difference with our reliable courier services.</div>
            <p>Our drivers are trained to ensure that your package arrives at its destination swiftly. We allow you to partner with us for your business and individual courier needs, enabling us to offer tailored courier solutions for businesses of all sizes and provide reliable logistics support. We prioritize secure and timely delivery of Confidential documents, fragile items, and perishable goods.</p>

            <div className="courier-custome-list-container">
                <div style={{ width: '100%' }}>
                    <div className="courier-custome-title">Unlock Convenience: Download the Pick’tUp App Now!</div>
                    <div className="courier-custome-title-description">Customer satisfaction is at the heart of what we do. We strive to exceed your expectations, delivering not just packages but peace of mind.</div>
                    <p>Ready to send your package? Trust the Pick’tUp app for a seamless courier experience. Download the App today!</p>
                    <div className='store-buton'>
                        <img src={androidStore} alt="iPhone"  />
                        <img src={appleStore} alt="iPhone" />
                    </div>

                </div>

                <div style={{ width: '80%' }}>
                    <img src={iPhone} alt="iPhone" className='iPhone-adjust' />
                </div>
            </div>
        </div>
    );
};

export default Courier;
