// export const BASE_URL = 'http://ec2-52-59-198-30.eu-central-1.compute.amazonaws.com/api/v1/'
export const BASE_URL = 'https://api.musuwologistics.co.za/api/v1/'
export const googleApiKey = 'AIzaSyD3BToDj_z_1ZLuhdDSURQplj3_9IgQSis';
export const api = {
    login: 'user/login',
    socialLogin: 'user/socialLogin',
    signUp: 'user/signup',
    verifyOtp: 'user/verify',
    deleteUser: 'user/deleteMe',
    sendOtp: 'user/sendOTP',
    forgetPassword: 'user/forgotpassword',
    verifyResetPasswordOtp: 'user/verifyOTPResetPassword',
    createNewPassword: 'user/resetPassword',
    completeProfile: 'user/setUserAccount',
    uploadFileToAWS: 'user/upload',
    getUser: 'user/me',
    updateUser: 'user',
    delivery: 'delivery',
    reviews: 'review',
    feedback: 'feedback/create'
}
