// src/ComingSoon.js

import React from 'react';
import './ComingSoon.css'; // Importing the CSS file for styling

const ComingSoon = () => {
    return (
        <div className="coming-soon-container">
            <h1>We're Coming Soon</h1>
            <p>Our website is under construction. We'll be here soon with our new awesome site</p>
        </div>
    );
};

export default ComingSoon;
