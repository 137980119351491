// Loader.js

import React from 'react';
import { css } from '@emotion/react';
import { RingLoader } from 'react-spinners';

const override = css`
  display: block;
  margin: 0 auto;
  border-color: red; // Customize the color if needed
`;

const Loader = (props) => {
    return (
        <div style={{ marginBottom: '50px', marginTop: '50px', display: 'flex', alignItems: 'center', justifyContent: "center", }}>
            <RingLoader css={override} size={150} color={props.color == 'white' ? 'white' : '#001F7A'} loading={true} />
        </div>
    );
};

export default Loader;
