// IMAGES
export const APP_LOGO = '../assets/icons/logo.png'
export const APPLE_LOGO = '../assets/icons/apple.png'
export const FACEBOOK_LOGO = '../assets/icons/facebook.png'
export const GOOGLE_LOGO = '../assets/icons/google.png'
export const AUTH_IMAGE = '../assets/icons/authImage.png'

// ICONS
export const HIDE_ICON = '../assets/icons/hide.png'
export const SHOW_ICON = '../assets/icons/show.png'

// Google Client ID
export const GOOGLE_CLIENT_ID = '844004722610-r79loiue8f12e2ih4ip998ua6k8v4ci4.apps.googleusercontent.com'