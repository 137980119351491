// Import necessary React components and styles
import React from 'react';
import { calender, clock, mapMarker, mapOval, Scoter } from '../../assets/icons';
import './DeliveryCard.css'; // Make sure to create a corresponding CSS file

// Define the React component
const DeliveryCard = ({ item }) => {
    const originalDate = new Date(item?.deliveryStartTime);
    // Add one day to the original date
    const nextDayDate = new Date(originalDate);
    nextDayDate.setDate(originalDate.getDate() + 1);
    // Format the new date to "YYYY-MM-DD"
    const formattedDate = nextDayDate.toISOString().split('T')[0];

    // time Conversion
    const timeValue = item?.deliveryStartTimeUnix; // Time in milliseconds since midnight
    const time = new Date(timeValue);
    // Format the time to HH:mm am/pm
    const formattedTime = time.toLocaleTimeString('en-US', {
        hour: 'numeric',
        minute: '2-digit',
        hour12: true,
    });

    return (
        <div className="your-container">
            {/* Header */}
            <div className="header">
                <div className="order-number">{item?.order?.orderno}</div>
                <div className="status" style={{ color: (item.status == 'delivery-inprogress') ? '#FF8A1B' : item.status == 'delivery-canceled' ? '#FF0202' : '#0C8E50' }}>Status: {(item.status == 'delivery-inprogress') ? 'In Processing' : (item.status == 'delivery-canceled') ? 'Cancelled' : 'Completed'}</div>
            </div>

            {/* Divider line */}
            <hr className="divider" />

            {/* Row view */}
            <div className="row-view">
                <div className="row-text">Location</div>
                <img src={Scoter} alt='scoter' className="row-icon" />
            </div>

            {/* Marker icon and text */}
            <div className="marker-view">
                <div style={{ display: 'flex', alignItems: 'center', marginBottom: '20px' }}>
                    <img src={mapOval} alt='Location Oval' className="location-icon" />
                    <div className="marker-text">{item?.pickUpLocation?.address}</div>
                </div>

                <div className="connector-line" />

                <div style={{ display: 'flex', alignItems: 'center', }}>
                    <img src={mapMarker} alt='Location Marker' className="location-icon" />
                    <div className="lower-text">{item?.dropOutLocation?.address}</div>
                </div>
            </div>

            {/* Footer */}
            <div className="row-text">Pickup Date & Time</div>
            <div className="footer">
                <div className="footer-left">
                    <img src={calender} alt='Calendar Icon' className="calendar-icon" />
                    <div className="date">{formattedDate}</div>
                </div>
                <div className="footer-right">
                    <img src={clock} alt='Clock Icon' className="clock-icon" />
                    <div className="time">{formattedTime}</div>
                </div>
            </div>
        </div>
    );
};

export default DeliveryCard;
