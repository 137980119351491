import { useSelector } from "react-redux";
import { useLocation } from "react-router";
import { Navigate } from "react-router-dom";

const PrivatedRoutes = (props) => {
    const location = useLocation();
    const user = useSelector((data) => data.userData.userData);
    const forgetOtpRoute = useSelector((data) => data.userData.forgetOtpRoute);
    const accessToken = useSelector((data) => data.userData.accessToken);

    return (
        <>
            {
                user
                    ? <>{props.children}</>
                    : forgetOtpRoute
                        ? <>{props.children}</>
                        : <Navigate to="/signin" replace state={{ from: location }} />
            }
        </>
    );
};

export default PrivatedRoutes;
