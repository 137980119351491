import React, { useState } from "react";
import { Footer, Header, Runner } from "../../../components";
import { useScrollTop } from "../../../services/helpingFunc";
import './RunnerSide.css'

export default function CourierScreen() {
    useScrollTop()

    return (
        <>
            {/* ******************** Header ******************** */}
            <Header title={'Runner'} />

            {/* ******************** Courier ******************** */}
            <Runner />

            {/* ******************** Footer ******************** */}
            <Footer />
        </>
    );
}
