// ContainerComponent.jsx
import React, { useState, useEffect } from 'react';
import { HomeLogo, logo } from '../../assets/icons';
import TextInput from '../textInput/TextInput';
import './CustomeSupport.css'; // Import the CSS file for styling
import GoogleMapReact from 'google-map-react';
import { api, googleApiKey } from '../../network/Environment';
import Marker from '../MarkerWrapper/MarkerWrapper';
import { notifyToast } from '../../services/helpingFunc';
import { callApi, Method } from '../../network/NetworkManger';

const defaultProps = {
    center: {
        lat: -26.056802,
        lng: 28.026524
    },
    zoom: 11
};

const CustomeSupport = () => {
    const [currentLocation, setCurrentLocation] = useState(defaultProps);
    const [message, setMessage] = useState('');
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');

    useEffect(() => {
        // Get current location using browser's Geolocation API
        if (navigator.geolocation) {
            navigator.geolocation.getCurrentPosition(
                (position) => {
                    const { latitude, longitude, accuracy } = position.coords;
                    console.log("Current Position: ", position.coords)
                    setCurrentLocation({
                        center: {
                            lat: latitude,
                            lng: longitude
                        },
                        zoom: accuracy
                    });
                },
                (error) => {
                    console.error('Error getting current location:', error.message);
                }
            );
        } else {
            console.error('Geolocation is not supported by this browser.');
        }
    }, []);

    const sendFeeback = async () => {
        if (name.length == 0 && email.length == 0 && message.length == 0) {
            notifyToast('Please Enter all field data');
        } else {
            const onSuccess = response => {
                console.log('Get Feeback Response: ', response)
                notifyToast('FeedBack Send Successfully!');

                setMessage('')
                setName('')
                setEmail('')
            };

            const onError = error => {
                console.log('Get Feeback Error: ', error)
                notifyToast(error?.message);
            };

            const endPoint = api.feedback
            const method = Method.POST;
            const bodyParams = {
                "feedback": message
            }

            await callApi(method, endPoint, bodyParams, onSuccess, onError);
        }
    }

    return (
        <div className="custome-main-container">
            <div className="custome-main-container-centerAlign">
                <div className="custome-logo-container">
                    <img src={HomeLogo} alt="Logo" />
                </div>
                <div className="custome-logo-title">Customer Support</div>
                <div className="custome-title">Contact Us</div>
                <div className="custome-title-description">For assistance with orders, product inquiries, or general questions, our customer support team is ready to help. Our commitment to exceptional customer service extends beyond transactions. We value every interaction and look forward to serving you with excellence.</div>
            </div>
            <div className="custome-list-container">
                <div>
                    <TextInput value={name} onChange={(e) => setName(e.target.value)} placeholder={'Enter Name'} title={'Full Name'} />
                    <TextInput value={email} onChange={(e) => setEmail(e.target.value)} placeholder={'Enter Email Address'} title={'Email'} />
                    <TextInput value={message} textArea onChange={(e) => setMessage(e.target.value)} placeholder={'Message....'} title={'Message'} />
                    <div style={{ alignItems: "center", justifyContent: 'center', display: 'flex' }}>
                        <button onClick={() => sendFeeback()} className="review-custom-button" style={{ backgroundColor: '#001F7A', color: '#fff' }}>Send Message</button>
                    </div>
                </div>

                <div className="dynamic-map-container">
                    <div className="dynamic-map">
                        <GoogleMapReact
                            bootstrapURLKeys={{ key: googleApiKey }}
                            defaultCenter={defaultProps.center}
                            // center={currentLocation.center}
                            // zoom={currentLocation.zoom}
                            center={defaultProps.center}
                            zoom={defaultProps.zoom}
                            defaultZoom={defaultProps.zoom}>
                            <Marker key={'1'} text={'current location'} lat={defaultProps.center.lat} lng={defaultProps.center.lng} />
                        </GoogleMapReact>
                    </div>
                </div>
            </div>
        </div >
    );
};

export default CustomeSupport;
