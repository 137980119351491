import React from 'react';
import { Values_Image, vision1, vision2, vision3, vision4 } from '../../assets/icons';
import './ourVision.css'; // Make sure to create this CSS file

const OurVision = () => {
    return (
        <div className="vision-container">
            <div className="vision-left-section">
                <img src={vision4} alt="visions" style={{ borderRadius: 20 }} />
            </div>
            <div className="vision-right-section">
                <div>
                    <p>Our Vision</p>
                    {/* <h1>Lorem ipsum dolor sit amet, conse ctetur </h1> */}
                    <div>
                        <span>Founded in 2022, we are a dynamic and forward-thinking digital logistics company that brings a fresh perspective to the goods delivery landscape. With a team of industry experts, cutting-edge technology, and a commitment to excellence, we aim to emerge as a renowned Logistics company worldwide. </span>
                    </div>
                    <div style={{ marginTop: '10px' }}>
                        <span>Our vision involves providing safe and reliable logistics services to ensure that our customers can run their businesses efficiently by offering an undisrupted goods delivery experience.  </span>
                    </div>
                    <div style={{ marginTop: '10px' }}>
                        <span>We envision a world where logistics is not just about moving goods; it's about creating connections, fostering growth, and enabling businesses to reach new heights. Our main goal is to be the logistical backbone that propels industries forward. </span>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default OurVision;
