import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Button, Loader, TextInput } from "../../../components";
import "./completeProfile.css";
import { camera, logo, authImage, HomeLogo } from "../../../assets/icons";
import { api, googleApiKey } from "../../../network/Environment";
import { callApi, Method } from "../../../network/NetworkManger";
import { v4 as uuidv4 } from 'uuid';
import { CreateUUID, notifyToast, uploadImageOnS3, useScrollTop } from "../../../services/helpingFunc";
import { useDispatch, useSelector } from "react-redux";
import { accessToken, refreshToken, userData } from "../../../redux/Slices/userDataSlice";
import { usePlacesWidget } from "react-google-autocomplete";
import { geocodeByPlaceId, getLatLng, geocodeByAddress } from 'react-google-places-autocomplete';
import usePlacesService from "react-google-autocomplete/lib/usePlacesAutocompleteService";

export default function CompleteProfile() {
    useScrollTop()

    const navigate = useNavigate();
    const dispatch = useDispatch();

    const user = useSelector((data) => data.userData.userData);
    const [name, setName] = useState(user?.user?.name ? user?.user?.name : '')
    const [phoneNumber, setPhoneNumber] = useState(user?.user?.number ? user?.user?.number : '')
    const [location, setLocation] = useState(user?.user?.location?.description ? user?.user?.location?.description : '');
    const [coordinated, setCoordinated] = useState({ lat: user?.user?.location ? user?.user?.location?.coordinates[1] : '', lng: user?.user?.location ? user?.user?.location?.coordinates[0] : '' });
    const [imageSrc, setImageSrc] = useState(user?.user?.image ? user?.user?.image : null);
    const [selectedImage, setSelectedImage] = useState(null);
    const [loading, setLoading] = useState(false);
    const [placeDetailsToState, savePlaceDetailsToState] = useState(null);

    const { placePredictions, getPlacePredictions, isPlacePredictionsLoading } = usePlacesService({
        apiKey: googleApiKey,
    });

    React.useEffect(() => {
        // fetch place details for the first element in placePredictions array
        if (placePredictions.length) {
            savePlaceDetailsToState(placePredictions)
        } else {
            savePlaceDetailsToState(null)
        }
    }, [placePredictions]);

    const getLatLonusingAddress = async (item) => {
        setLocation(item?.description)
        geocodeByAddress(`${item?.description}`)
            .then(results => getLatLng(results[0]))
            .then(({ lat, lng }) => {
                console.log('Successfully got latitude and longitude', { lat, lng })
                setCoordinated({ lat, lng })
            });
        savePlaceDetailsToState(null)
    }

    const { ref } = usePlacesWidget({
        apiKey: googleApiKey,
        onPlaceSelected: (place) => {
            console.log("Current places", place);
            setLocation(place?.formatted_address)
            geocodeByAddress(`${place?.address_components[0]?.long_name}, ${place?.address_components[place?.address_components.length - 1]?.long_name}`)
                .then(results => getLatLng(results[0]))
                .then(({ lat, lng }) => {
                    console.log('Successfully got latitude and longitude', { lat, lng })
                    setCoordinated({ lat, lng })
                });
        },
    });

    const handleImageUpload = (e) => {
        const file = e.target.files[0];
        setSelectedImage(file);

        if (file) {
            const reader = new FileReader();
            reader.onload = (e) => {
                setImageSrc(e.target.result);
            };
            reader.readAsDataURL(file);
        }
    };

    const onPressUploadImage = async () => {
        try {
            if (selectedImage) {
                setLoading(true);
                const imageUrl = await uploadImageOnS3(selectedImage);
                console.log('Image uploaded to S3:', imageUrl);
                if (imageUrl?.url) {
                    completeProfile(imageUrl?.url)
                }
            } else {
                setLoading(false);
                // completeProfile(imageSrc)
                notifyToast('Please Select Image!');
            }
        } catch (error) {
            setLoading(false);
            console.error("Error uploading image:", error);
        }
    };

    const completeProfile = async (imagePath) => {
        const onSuccess = response => {
            setLoading(false);
            console.log('complete Profile: ', response)
            notifyToast(response.message);
            const updatedUserData = {
                user: response?.data?.user,
            };
            const tokenData = {
                token: response?.data?.token,
                refreshToken: response?.data?.refreshToken,
            };

            console.log(updatedUserData, tokenData)

            dispatch(accessToken(tokenData?.token))
            dispatch(refreshToken(tokenData?.refreshToken))
            dispatch(userData(updatedUserData))
            // Navigation to Stack
            navigate('/')
        };

        const onError = error => {
            setLoading(false);
            console.log('complete Profile: ', error)
            notifyToast(error?.message);
        };

        const endPoint = api.completeProfile;
        const method = Method.POST;
        const bodyParams = {
            "name": name,
            "number": phoneNumber,
            "timeZone": Intl.DateTimeFormat().resolvedOptions().timeZone,
            "image": imagePath,
            "location": {
                "type": "Point",
                "coordinates": [
                    coordinated.lng,
                    coordinated.lat,
                ],
                "address": location,
                "description": ""
            },
            "device": {
                id: CreateUUID(),
                deviceToken: CreateUUID()
            }
        }

        callApi(method, endPoint, bodyParams, onSuccess, onError);
    }

    return (
        <div className="profile-auth-container">
            <Loader loading={loading} />
            <div className="profile-scroll">
                <div onClick={() => navigate('/')}>
                    <img src={HomeLogo} alt='logo' className='profile-image_logo_style' />
                </div>
                <div className="profile-detail_view">
                    <h1>Complete your Profile!</h1>
                    <h2>Please complete your profile details to continue</h2>
                    <div className='profile-circle-view'>
                        {
                            imageSrc && <img src={imageSrc} alt="Uploaded" className='profile-circle-view' />
                        }
                        <div className='profile-camera-icon' onClick={() => document.getElementById('fileInput').click()}>
                            <img src={camera} alt="Camera Icon" />
                        </div>
                    </div>
                    <input type="file" id="fileInput" accept="image/*" onChange={handleImageUpload} style={{ display: 'none' }} />

                    <TextInput onChange={(e) => setName(e.target.value)} placeholder={'Enter Name'} title={'Full Name'} />
                    <TextInput onChange={(e) => setPhoneNumber(e.target.value)} placeholder={'Enter Phone Number'} title={'Enter Phone Number'} />
                    {/* <TextInput ref={ref} onChange={(e) => setLocation(e.target.value)} placeholder={'Enter Location'} title={'Enter Location'} /> */}
                    {/* <div className="google_input">
                        <p>{'Enter Location'}</p>
                        <div className='google_input_container'>
                            <input ref={ref} value={location} onChange={(e) => setLocation(e.target.value)} placeholder='Enter Location' />
                        </div>
                    </div> */}

                    <div className="google_input">
                        <p>{'Enter Location'}</p>
                        <div>
                            <div className='google_input_container'>
                                <input
                                    value={location}
                                    placeholder='Enter Location'
                                    onChange={(evt) => {
                                        getPlacePredictions({ input: evt.target.value });
                                        setLocation(evt.target.value)
                                    }}
                                    loading={isPlacePredictionsLoading}
                                />
                            </div>
                            <div>
                                <div style={{ position: 'absolute', backgroundColor: 'white', boxShadow: '0 2px 4px rgba(0,0,0,0.1)', borderRadius: '10px' }}>
                                    {placeDetailsToState != null && placeDetailsToState.map((item, index) => {
                                        return (
                                            <div key={index} onClick={() => getLatLonusingAddress(item)} style={{ borderBottom: '1px solid rgba(0,0,0,0.1)', cursor: 'pointer', padding: '20px' }}>
                                                {item?.description}
                                            </div>
                                        )
                                    })}
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="profile-button-view">
                        <Button onClick={() => onPressUploadImage()} >Continue</Button >
                    </div>
                </div>
            </div>
            <div className="profile-image_sideView">
                <img src={authImage} alt='authImage' className='profile-image_sideStyle' />
                <div className="image-caption">
                    <h3 className="image-title">Welcome</h3>
                    <p className="image-description">Musuwo Logistics</p>
                </div>
            </div>
        </div>
    );
}
