// ContainerComponent.jsx
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { BagBlueIcon, HeartBlueIcon, HomeLogo, logo, MotorBikeImage, PickItUpImage, ReferralCodeIcon, RunnerUpImage, sendIcon, whatsAppIcon } from '../../assets/icons';
import './benefits.css'; // Import the CSS file for styling

const NetworkBenefits = () => {
    const navigate = useNavigate()
    const jotformURL = 'https://form.jotform.com/233102101836037?fbclid=PAAabf4G3BUd7rrmFb_2aJRVpcWBELQHwAuXaPiiHMExbT-PCdJmBKFwVg2S0_aem_AYUKGgNxS4IsNHIwforL9DS0ZAvi64T9QYNvzI2nMcxwCa5F_Ae0wsdcvIwqFkb9qRw';

    const openWhatsAppChat = () => {
        const phoneNumber = '27818887653'; // Formatted phone number without spaces or special characters
        const message = 'Hello!'; // Replace with the initial message (optional)

        const whatsappURL = `https://wa.me/${phoneNumber}?text=${encodeURIComponent(message)}`;

        window.open(whatsappURL, '_blank');
    };

    return (
        <div className="benefit-main-container">
            <div className='benefit-main-container-centerAlign'>
                <div className="benefit-logo-container">
                    <img src={HomeLogo} alt="Logo" />
                </div>
                <div className="benefit-logo-title">Network Benefits</div>
                <div className="benefit-title">Join Our Courier Network</div>
                <div className="benefit-title-description">Becoming a part of our Member Network is simple. Sign up by filling an e-form today and embark on a journey of collaboration, growth, and exciting delivery discounts. Our network is open to both businesses and individuals.</div>
            </div>
            <div className="benefit-list-container">
                <div className="benefit-list-item">
                    <img src={sendIcon} alt="Item 1" className="benefit-item-image" />
                    <div className="benefit-item-title">Discounts</div>
                    <div className="benefit-item-description">There will be an exclusive limited-time offers just for you and your business. Say hello to extra savings, incredible deals, and the perfect opportunity to get your goods picked up and dropped off securely below your budget.</div>
                </div>
                <div className="benefit-list-item">
                    <img src={HeartBlueIcon} alt="Item 2" className="benefit-item-image" />
                    <div className="benefit-item-title">New Feature</div>
                    <div className="benefit-item-description">You will be the first one to learn about new features on our app, that will make your logistic needs easy and seamless to manage. You will get an opportunity to send us your recommendations, pain points and how we can improve your experience.</div>
                </div>
                <div className="benefit-list-item">
                    <img src={BagBlueIcon} alt="Item 3" className="benefit-item-image" />
                    <div className="benefit-item-title">Free Packaging Bags</div>
                    <div className="benefit-item-description">We believe in making your delivery experience not only delightful but also professional. That's why, for the first time, we're thrilled to offer you complimentary Pick’tUp bags when you join the Courier Partners Network.</div>
                </div>
                <div className="benefit-list-item">
                    <img src={ReferralCodeIcon} alt="Item 3" className="benefit-item-image" />
                    <div className="benefit-item-title">Referral Codes</div>
                    <div className="benefit-item-description">We believe that the best compliments come from our members. That's why we're excited to provide a way for you to earn rewards while spreading the word about the products and services you love. When your friends make their first purchase using your referral code, not only do they get a fantastic deal, but you also start earning points.</div>
                </div>
            </div>
            <div style={{ marginTop: '20px', alignItems: "center", justifyContent: 'center', display: 'flex' }}>
                <button onClick={() => window.open(jotformURL, '_blank')} className="review-custom-button" style={{ backgroundColor: '#001F7A', color: '#fff' }}>Join Courier Network</button>
            </div>

            <div onClick={openWhatsAppChat} style={{ height: '50px', alignItems: "center", justifyContent: 'flex-end', display: 'flex', alignSelf: 'flex-end' }}>
                <img src={whatsAppIcon} alt="whatsapp" className='whatsappImage' />
            </div>
        </div>
    );
};

export default NetworkBenefits;
