import React from "react";
import { Routes, Route } from "react-router-dom";
import * as Viewers from "../pages/viewers";
import PrivateRoute from "./PrivateRoute";

const Navigation = () => {
  return (
    <Routes>
      {/* Public Routes */}
      <Route path="/" element={<Viewers.Home />} />
      <Route path="/Home" element={<Viewers.Home />} />
      <Route path="/courier" element={<Viewers.CourierScreen />} />
      <Route path="/runner" element={<Viewers.RunnerSide />} />
      <Route path="/motoBike" element={<Viewers.MotorBikeHire />} />
      <Route path="/aboutUs" element={<Viewers.AboutUs />} />
      <Route path="/courierNetwork" element={<Viewers.CourierNetwork />} />
      <Route path="/contactUs" element={<Viewers.ContactUs />} />
      <Route path="/privacyPolicy" element={<Viewers.PrivacyPolicy />} />
      <Route path="*" element={<Viewers.PageNotFound />} />

      {/* Protected Routes (User must be signed out) */}
      <Route path="/signin" element={<Viewers.SignIn />} />
      <Route path="/signup" element={<Viewers.SignUp />} />
      <Route path="/forgetPassword" element={<Viewers.ForgetPassword />} />

      {/* Private Routes (User must be signed in) */}
      <Route path="/OtpVerify" element={<Viewers.OtpVerify />} />
      <Route path="/resetPassword" element={<Viewers.ResetPassword />} />
      <Route path="/completeProfile" element={<Viewers.CompleteProfile />} />
      <Route path="/myProfile" element={<PrivateRoute><Viewers.MyProfile /></PrivateRoute>} />
      <Route path="/pastDeliveries" element={<PrivateRoute><Viewers.PastDelivery /></PrivateRoute>} />

      {/* Uncomment the following line if you wish to use it in the future */}
      {/* <Route path="/vouchers" element={<PrivateRoute><Viewers.MyVouchers /></PrivateRoute>} /> */}
    </Routes>
  );
};

export default Navigation;
