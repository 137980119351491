import React, { useState } from "react";
import "./signUp.css";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Button, Loader, TextInput } from "../../../components";
import { apple, logo, authImage, facebook, google, hide, show, HomeLogo } from "../../../assets/icons";
import { callApi, Method } from "../../../network/NetworkManger";
import { api } from "../../../network/Environment";
import { isSignupValid } from "../../../services/validation";
import { v4 as uuidv4 } from 'uuid';
import { CreateUUID, notifyToast, useScrollTop } from "../../../services/helpingFunc";
import { useGoogleLogin } from '@react-oauth/google';
import { accessToken, refreshToken, SaveRememberMe, userData } from "../../../redux/Slices/userDataSlice";

const socialArray = [
    {
        id: 1,
        icon: google
    },
    // {
    //     id: 2,
    //     icon: apple
    // },
    // {
    //     id: 3,
    //     icon: facebook
    // }
]

export default function SignUp() {
    useScrollTop()

    const navigate = useNavigate();
    const dispatch = useDispatch()
    const [showPassword, setShowPassword] = useState(false)
    const [showConfirmPassword, setShowConfirmPassword] = useState(false)
    const [email, setEmail] = useState('')
    const [password, setPassword] = useState('')
    const [confirmPassword, setConfirmPassword] = useState('')
    const [loading, setLoading] = useState(false);

    //SignUp with email and password
    const handleSignUp = () => {
        if (email?.length == 0 && password?.length === 0 && confirmPassword?.length === 0) {
            notifyToast('Please Enter all field data');
        } else {
            if (isSignupValid(null, email, password, confirmPassword)) {
                setLoading(true)

                const onSuccess = response => {
                    notifyToast(response?.message);
                    console.log('success', 'SUCCESS', response)
                    const updatedUserData = {
                        user: response?.data?.user,
                    };
                    console.log('success', 'updatedUserData', updatedUserData);
                    dispatch(userData(updatedUserData))
                    setLoading(false);
                    dispatch(SaveRememberMe(true))
                    navigate('/OtpVerify', { state: { email: email.toLowerCase(), key: 'auth' } });
                }
                const onError = error => {
                    console.log('error', 'ERROR', error);
                    console.log('error', 'error.message', error?.message);
                    notifyToast(error?.message);
                    setLoading(false);
                }
                const endPoint = api.signUp;
                const method = Method.POST;
                const bodyParams = {
                    email: email.toLowerCase(),
                    password: password,
                    role: 'client',
                    device: {
                        id: CreateUUID(),
                        deviceToken: CreateUUID()
                    },
                };
                callApi(method, endPoint, bodyParams, onSuccess, onError);
            }
        };
    }

    const fetchEmail = (tokenResponse) => {
        var myHeaders = new Headers();
        myHeaders.append("Authorization", `Bearer ${tokenResponse?.access_token}`);

        var requestOptions = {
            method: 'GET',
            headers: myHeaders,
            redirect: 'follow'
        };

        fetch("https://www.googleapis.com/oauth2/v3/userinfo", requestOptions)
            .then(response => response.json())
            .then(result => {
                console.log(result)
                const onSuccess = response => {
                    notifyToast(response.message);
                    const updatedUserData = {
                        user: response?.data?.user,
                    };
                    const tokenData = {
                        token: response?.data?.token,
                        refreshToken: response?.data?.refreshToken,
                    };
                    console.log('success', 'updatedUserData', updatedUserData);
                    console.log('success', 'tokenData', tokenData);
                    dispatch(userData(updatedUserData))
                    dispatch(accessToken(tokenData?.token))
                    dispatch(refreshToken(tokenData?.refreshToken))
                    console.log('updatedUserData?.user?.isComplete: ', updatedUserData?.user?.isComplete)
                    console.log('updatedUserData?.user?.isComplete typeof: ', typeof updatedUserData?.user?.isComplete)

                    updatedUserData?.user?.isComplete ? navigate('/') : navigate('/completeProfile');
                };
                const onError = error => {
                    console.log('error', 'ERROR', error);
                    notifyToast(error?.message);
                    setLoading(false)
                    if (error?.errorType === 'email-not-verify') {
                        navigate('/OtpVerify', { state: { email: email.toLowerCase(), key: 'auth' } });
                    }
                };
                const endPoint = api.socialLogin;
                const method = Method.POST;
                const bodyParams = {
                    email: result?.email.toLowerCase(),
                    role: 'client',
                    device: {
                        id: CreateUUID(),
                        deviceToken: CreateUUID()
                    },
                };

                callApi(method, endPoint, bodyParams, onSuccess, onError);

            })
            .catch(error => console.log('error', error));
    }

    const googleLogin = useGoogleLogin({
        onSuccess: tokenResponse => {
            console.log(tokenResponse)
            fetchEmail(tokenResponse)
        },
    });

    const SocialLogin = (type) => {
        if (type == 0) {
            googleLogin()
        } else {
            notifyToast('Apple Login! Coming Soon')
        }
    }

    return (
        <div className="signup-auth-container">
            <Loader loading={loading} />
            <div className="signup-scroll">
                <div onClick={() => navigate('/')}>
                    <img src={HomeLogo} alt='logo' className='signup-image_logo_style' />
                </div>
                <div className="signup-detail_view">
                    <h1>Create Account!</h1>
                    <h2>Create your account to get started</h2>
                    <TextInput onChange={(e) => setEmail(e.target.value)} placeholder={'Enter Email Address'} title={'Email'} />
                    <TextInput onChange={(e) => setPassword(e.target.value)} onClickEye={() => setShowPassword(!showPassword)} type={!showPassword ? 'password' : 'text'} eye eyeIcon={showPassword ? show : hide} placeholder={'Enter Password'} title={'Password'} />
                    <TextInput onChange={(e) => setConfirmPassword(e.target.value)} onClickEye={() => setShowConfirmPassword(!showConfirmPassword)} type={!showConfirmPassword ? 'password' : 'text'} eye eyeIcon={showConfirmPassword ? show : hide} placeholder={'Enter Password'} title={'Confirm Password'} />
                    <div className="signup-button-view">
                        <Button onClick={() => handleSignUp()}>Sign Up</Button >
                    </div>
                    <div onClick={() => navigate('/signin')} className="signin-dont_have_account_view">
                        <h3> Already have an account?  {" "} <span style={{ color: '#001F7A', textDecorationLine: "underline" }}>Login</span> </h3>
                    </div>
                    <div className="signup-or-divider">
                        <div className="signup-line"></div>
                        <div className="signup-or-text">or</div>
                        <div className="signup-line"></div>
                    </div>
                    <div className="signup-social_view">
                        {socialArray.map((item, index) => {
                            return (
                                <div onClick={() => SocialLogin(index)} key={index}>
                                    <img src={item.icon} alt='icon' />
                                </div>
                            )
                        })}
                    </div>
                </div>
            </div>
            <div className="signup-image_sideView">
                <img src={authImage} alt='authImage' className='signup-image_sideStyle' />
                <div className="image-caption">
                    <h3 className="image-title">Welcome</h3>
                    <p className="image-description">Musuwo Logistics</p>
                </div>
            </div>
        </div>
    );
}
