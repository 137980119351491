// ContainerComponent.jsx
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { HomeLogo, logo, MotorBikeImage, PickItUpImage, RunnerUpImage } from '../../assets/icons';
import './ourServices.css'; // Import the CSS file for styling

const OurServices = () => {
    const navigate = useNavigate()

    return (
        <div className="main-container">
            <div className="logo-container">
                <img src={HomeLogo} alt="Logo" />
            </div>
            <div className="logo-title">Our Services</div>
            <div className="title">One Solution to Meet All Your Logistics Needs</div>
            <div className="title-description">Our services ensure that your business is running efficiently and helping you offer an undisrupted goods delivery experience.</div>

            <div className="list-container">
                <div onClick={() => navigate('/courier')} className="list-item">
                    <img src={PickItUpImage} alt="Item 1" className="item-image" />
                    <div className="item-title">Courier</div>
                    <div className="item-description">We understand the importance of reliable and efficient courier services in today's fast-paced world. Whether you're a business needing seamless deliveries or an individual with a package to send, we've got you covered. Our commitment is to provide top-notch courier solutions that ensure your parcels reach their destination safely and on time.</div>
                </div>
                <div onClick={() => navigate('/runner')} className="list-item">
                    <img src={RunnerUpImage} alt="Item 2" className="item-image" />
                    <div className="item-title">Specialized Delivery</div>
                    <div className="item-description">Our Specialized Delivery Service is committed to providing a reliable and responsive solution for your logistics challenges. Let us handle the delivery so that you can focus on what matters most—growing your business. Ready to optimize your logistics? Contact us today and experience the efficiency of our Delivery Service.</div>
                </div>
                <div onClick={() => navigate('/motoBike')} className="list-item">
                    <img src={MotorBikeImage} alt="Item 3" className="item-image" />
                    <div className="item-title">Motorbike Hire</div>
                    <div className="item-description">Are you a registered fast food delivery driver looking to enhance delivery services? Look no further. We offer brand-new, efficient, and well-serviced motorbike hire services to meet the unique needs of the food industry.</div>
                </div>
            </div>
        </div>
    );
};

export default OurServices;
