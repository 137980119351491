// ContainerComponent.jsx
import React from 'react';
import { CostSolution, CustomerSupport, CuttingTechnology, HomeLogo, logo, MotorBikeImage, PickItUpImage, ReliableSupport, RunnerUpImage } from '../../assets/icons';
import './letEnjoy.css'; // Import the CSS file for styling

const LetEnjoy = () => {
    return (
        <div className="enjoy-main-container">
            <div className="enjoy-logo-container">
                <img src={HomeLogo} alt="Logo" />
            </div>
            <div className="enjoy-logo-title">Let's Enjoy</div>
            <div className="enjoy-title">Why Choose US</div>
            <div className="enjoy-title-description">We understand that choosing the right logistics partner is crucial for the success of your business. Our commitment to excellence, reliability, and innovation sets us apart. Here are compelling reasons why you should choose us for all your logistics needs:</div>
            <div className="enjoy-list-container">
                <div className="enjoy-list-item">
                    <img src={CuttingTechnology} alt="Item 1" className="enjoy-item-image" />
                    <div className="enjoy-item-description">We embrace the future of logistics with our state-of-the-art technology. Real-time tracking, advanced analytics, and efficient routing systems ensure your shipments are handled securely, precisely, and carefully.</div>
                </div>
                <div className="enjoy-list-item">
                    <img src={ReliableSupport} alt="Item 2" className="enjoy-item-image" />
                    <div className="enjoy-item-description">Trust is the foundation of our service. We commit to a good track record of on-time deliveries to ensure that your shipments reach their destination safely and efficiently.</div>
                </div>
                <div className="enjoy-list-item">
                    <img src={CustomerSupport} alt="Item 3" className="enjoy-item-image" />
                    <div className="enjoy-item-description">Your Satisfaction, Our Priority! We have deployed a dedicated customer support team to ensure that your queries are addressed, to provide updates, and to ensure a smooth logistics experience so that you can focus on your core business.</div>
                </div>
                <div className="enjoy-list-item">
                    <img src={CostSolution} alt="Item 3" className="enjoy-item-image" />
                    <div className="enjoy-item-description">Value Without Compromise! You will achieve cost savings without compromising on quality. Our efficient logistics solutions are designed to optimize your supply chain, offering value for every cent spent.</div>
                </div>
            </div>
        </div>
    );
};

export default LetEnjoy;
