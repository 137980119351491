import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { mapMarker } from '../../assets/icons';

const Marker = ({ text }) => (
    <img src={mapMarker} alt={text} style={{ width: 20, height: 25 }} />
);

Marker.defaultProps = {
    onClick: null,
};

Marker.propTypes = {
    onClick: PropTypes.func,
    text: PropTypes.string.isRequired,
};

export default Marker;