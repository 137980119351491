import React from 'react'
import './footer.css'
import { Call, Facebook_Diamond, HomeLogoWhite, logo, Instagram_Diamond, Linkedin_Diamond, Location, Message, Twitter_Diamond, whatsapp, Youtube_Diamond, HomeLogo } from '../../assets/icons'
import { useNavigate } from 'react-router-dom'

export default function Footer() {
    const navigate = useNavigate()

    const handleSocialButtonClick = (socialLink) => {
        window.open(socialLink, '_blank');
    };

    const openWhatsAppChat = () => {
        const phoneNumber = '27818887653'; // Formatted phone number without spaces or special characters
        const message = 'Hey!'; // Replace with the initial message (optional)
        const whatsappURL = `https://wa.me/${phoneNumber}?text=${encodeURIComponent(message)}`;
        window.open(whatsappURL, '_blank');
    };

    const handleMapClick = (address) => {
        const mapUrl = `https://www.google.com/maps/search/?api=1&query=${encodeURIComponent(address)}`;
        window.open(mapUrl, '_blank');
    };

    const handleEmailClick = (email) => {
        const gmailUrl = `https://mail.google.com/mail/?view=cm&fs=1&to=${email}`;
        window.open(gmailUrl, '_blank');
    };

    return (
        <div className="footer_main_container">
            <div className="footer_container">
                <div className="footer_row_view2">
                    <img className='logo_image' src={HomeLogo} alt="Logo" />

                    <div style={{ marginTop: '20px' }}>
                        <span>Please stay in the loop and be a part of our vibrant community! Connect with us on our social media pages for the latest updates, exclusive offers, and behind-the-scenes glimpses. Join the conversation, and let's share the excitement.</span>
                    </div>
                </div>
                <div className="footer_row_view">
                    <h2>Quick Links</h2>
                    <span onClick={() => navigate('/')}>Home</span>
                    <span onClick={() => navigate('/aboutUs')}>About Us</span>
                    <span onClick={() => navigate('/courierNetwork')}>Courier Network</span>
                    <span onClick={() => navigate('/contactUs')}>Contact Us</span>
                </div>

                <div className="footer_row_view">
                    <h2>Our Services</h2>
                    <span onClick={() => navigate('/courier')}>Courier</span>
                    <span onClick={() => navigate('/runner')}>Specialized Delivery</span>
                    <span onClick={() => navigate('/motoBike')}>Motorbike Hire</span>
                </div>

                <div className="footer_row_view">
                    <h2>Support</h2>
                    <div>
                        <img src={Call} alt='phoneCall' />
                        <span>011 054 2528</span>
                    </div>
                    <div onClick={() => openWhatsAppChat()}>
                        <img src={whatsapp} alt='whatsappCall' />
                        <span>+27 (81) 888-7653</span>
                    </div>
                    <div onClick={() => handleEmailClick('admin@musuwologistics.co.za')}>
                        <img src={Location} alt='email' />
                        <span>admin@musuwologistics.co.za</span>
                    </div>
                    <div onClick={() => handleMapClick('41 Sloane Street, Bryanston, Johannesburg, 2191')}>
                        <img src={Message} alt='address' />
                        <span>41 Sloane Street, Bryanston, Johannesburg, 2191</span>
                    </div>
                </div>
            </div>
            <div className="footer_social_top_view">
                <img onClick={() => handleSocialButtonClick('https://www.facebook.com/profile.php?id=10009458034330')} src={Facebook_Diamond} alt={'facebook'} />
                <img onClick={() => handleSocialButtonClick('https://www.instagram.com/musuwologistics?igsh=bWJgdG')} src={Instagram_Diamond} alt={'Insatgram'} />
                {/* <img src={Twitter_Diamond} alt={'Twitter'} /> */}
                <img onClick={() => handleSocialButtonClick('https://www.linkedin.com/company/12640758/admin/feed/')} src={Linkedin_Diamond} alt={'Linkedin'} />
                <img onClick={() => handleSocialButtonClick('https://www.youtube.com/channel/UCJkyTQPE81HJEBubtp0')} src={Youtube_Diamond} alt={'Youtube'} />
            </div>
            <div className='line-divider' />
            <span className='footer_copy_right'>@2023 Musuwo Logistics. All Right Reserved</span>
        </div>
    )
}

