// ContainerComponent.jsx
import React, { useState, useEffect } from 'react';
import { agile, calling, DemandService, HomeLogo, logo, LocationCalling, messagingCalling, scheduling, timeTracking, whatsappCalling } from '../../assets/icons';
import './Runner.css'; // Import the CSS file for styling
import GoogleMapReact from 'google-map-react';
import { googleApiKey } from '../../network/Environment';
import Marker from '../MarkerWrapper/MarkerWrapper';

const defaultProps = {
    center: {
        lat: -26.056802,
        lng: 28.026524
    },
    zoom: 11
};

const Runner = () => {
    const [currentLocation, setCurrentLocation] = useState(defaultProps);
    const [readMore, setReadMore] = useState(false);

    useEffect(() => {
        // Get current location using browser's Geolocation API
        if (navigator.geolocation) {
            navigator.geolocation.getCurrentPosition(
                (position) => {
                    const { latitude, longitude, accuracy } = position.coords;
                    console.log("Current Position: ", position.coords)
                    setCurrentLocation({
                        center: {
                            lat: latitude,
                            lng: longitude
                        },
                        zoom: accuracy
                    });
                },
                (error) => {
                    console.error('Error getting current location:', error.message);
                }
            );
        } else {
            console.error('Geolocation is not supported by this browser.');
        }
    }, []);


    return (
        <div className="runner-main-container">
            <div className="runner-logo-container">
                <img src={HomeLogo} alt="Logo" />
            </div>
            <div className="runner-logo-title">Our Services</div>
            {/* <div className="runner-title">Runner</div> */}
            <div className="runner-title">Welcome to our Specialized Delivery Services</div>
            <div className="runner-title-description">Efficiency in logistics is crucial for the smooth operation of any business. At Musuwo Logistics, we take pride in offering specialized Delivery Services designed to streamline your supply chain, reduce downtime, and enhance overall productivity. Whether you need on-demand deliveries, urgent document transfers, or last-mile solutions, our dedicated delivery team is ready to be your reliable partners.</div>

            <div className="benefit-list-container" style={{ textAlign: 'left' }}>
                <div className="benefit-list-item">
                    <img src={agile} alt="Item 1" className="benefit-item-image" />
                    <div className="benefit-item-title">Swift and Agile Runners</div>
                    <div className="benefit-item-description">Our logistics runners are the backbone of our service. Trained to be swift, agile, and adaptable, they are equipped to handle diverse tasks efficiently. From picking up urgent shipments to delivering time-sensi{readMore ? 'tive documents, our runners are committed to meeting your logistic needs promptly.' : <span class="read-more" onClick={() => setReadMore(true)}> Read more</span>} </div>
                </div>
                <div className="benefit-list-item">
                    <img src={DemandService} alt="Item 2" className="benefit-item-image" />
                    <div className="benefit-item-title">On-Demand Service</div>
                    <div className="benefit-item-description">No more waiting around for scheduled pickups. With our on-demand logistics runner service, you can request a runner when needed, ensuring that your logistics are always in sync with your business demands.</div>
                </div>
                <div className="benefit-list-item">
                    <img src={timeTracking} alt="Item 3" className="benefit-item-image" />
                    <div className="benefit-item-title">Real-Time Tracking</div>
                    <div className="benefit-item-description">Stay informed about the status of your deliveries with our real-time tracking feature. Know precisely where your runner is and when your shipment will arrive.</div>
                </div>
                <div className="benefit-list-item">
                    <img src={scheduling} alt="Item 3" className="benefit-item-image" />
                    <div className="benefit-item-title">Flexible Scheduling</div>
                    <div className="benefit-item-description">We understand that logistics requirements can vary. Our flexible scheduling options allow you to plan and coordinate deliveries according to your business hours and customer expectations.</div>
                </div>
            </div>

            <div>
                <div className="runner-title-below">Contact Details</div>
                <div className="runner-title-description-below">You can contact us for a meeting or request to arrange for a call by using the contact details below:</div>
                <div className='main-map-container'>
                    <div>
                        <div className="runner-dropdown-option">
                            <img src={calling} alt="Call" />
                            <span>011 054 2528</span>
                        </div>
                        <div className="runner-dropdown-option">
                            <img src={whatsappCalling} alt="whatsapp" />
                            <span>+27 (81) 888-7653</span>
                        </div>
                        <div className="runner-dropdown-option">
                            <img src={messagingCalling} alt="messageIcon" />
                            <span>admin@musuwologistic.co.zo</span>
                        </div>
                        <div className="runner-dropdown-option">
                            <img src={LocationCalling} alt="Location" />
                            <span>41 Sloane Street, Bryanston, Johannesburg, 2191</span>
                        </div>
                    </div>

                    <div className="runner-dynamic-map-container">
                        <div className="dynamic-map">
                            <GoogleMapReact
                                bootstrapURLKeys={{ key: googleApiKey }}
                                defaultCenter={defaultProps.center}
                                // center={currentLocation.center}
                                // zoom={currentLocation.zoom}
                                center={defaultProps.center}
                                zoom={defaultProps.zoom}
                                defaultZoom={defaultProps.zoom}>
                                <Marker key={'1'} text={'current location'} lat={defaultProps.center.lat} lng={defaultProps.center.lng} />
                            </GoogleMapReact>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Runner;
