// ContainerComponent.jsx
import React from 'react';
import { Delivery, Footer, Header } from '../../../components';
import { useScrollTop } from '../../../services/helpingFunc';
import './PastDelivery.css'; // Import the CSS file for styling

const PastDelivery = () => {
    useScrollTop()

    return (
        <>
            {/* ******************** Header ******************** */}
            <Header title={'Past Deliveries'} />

            {/* ******************** Past Deliveries ******************** */}
            <Delivery />

            {/* ******************** Footer ******************** */}
            <Footer />
        </>
    );
};

export default PastDelivery;
