// ContainerComponent.jsx
import React from 'react';
import { useSelector } from 'react-redux';
import { HomeLogo, logo } from '../../assets/icons';
import { api } from '../../network/Environment';
import { callApi, Method } from '../../network/NetworkManger';
import { notifyToast, useScrollTop } from '../../services/helpingFunc';
import DeliveryCard from '../deliveryCard/DeliveryCard';
import Loader from '../spinLoader';
import './Delivery.css'; // Import the CSS file for styling

const ArrayData = [
    { id: 1, name: 'In Processing' },
    { id: 2, name: 'Completed' },
    { id: 3, name: 'Cancelled' },
]

const Delivery = () => {
    useScrollTop()
    const user = useSelector((data) => data.userData.userData);
    const [selectedItem, setSelectedItem] = React.useState(0);
    const [deliveryorders, setDeliveryOrders] = React.useState([
        [],
        [],
        []
    ]);
    const [loading, setLoading] = React.useState(false);

    const handleItemClick = (index) => {
        setSelectedItem(index);
    };

    React.useEffect(() => {
        GetDelivery()
    }, [])

    const GetDelivery = async () => {
        const onSuccess = response => {
            setLoading(false);
            console.log('Get Delivery Response: ', response)
            const orders = response?.data?.data
            // Separate array based on status
            const processingOrders = orders.filter(order => (order.status === 'delivery-inprogress'));
            const deliveryCanceledOrders = orders.filter(order => order.status === 'delivery-canceled');
            const deliveryCompletedOrders = orders.filter(order => order.status === 'delivery-completed');

            console.log("processingOrders: ", processingOrders)
            console.log("deliveryCanceledOrders: ", deliveryCanceledOrders)
            console.log("deliveryCompletedOrders: ", deliveryCompletedOrders)

            setDeliveryOrders([
                processingOrders,
                deliveryCompletedOrders,
                deliveryCanceledOrders
            ])

            // notifyToast(response.message);
        };

        const onError = error => {
            setLoading(false);
            console.log('Get Delivery Error: ', error)
            // notifyToast(error?.message);
        };

        const endPoint = api.delivery + `?creator=${user.user?.id}`
        const method = Method.GET;
        const bodyParams = {}

        setLoading(true)
        await callApi(method, endPoint, bodyParams, onSuccess, onError);
    }

    return (
        <div>
            <div className="delivery-main-container">
                <div className="delivery-logo-container">
                    <img src={HomeLogo} alt="Logo" />
                </div>
                <div className="delivery-logo-title" style={{ marginTop: 10 }}>Profile</div>
                <div className="delivery-title">Past Deliveries</div>
                <div className="delivery-title-description">View your past deliveries here.</div>

                <div className="delivery-row-list">
                    {ArrayData.map((item, index) => (
                        <div key={index} className={`delivery-list-item ${selectedItem === index ? 'selected' : ''}`} onClick={() => handleItemClick(index)}>
                            {item.name}
                        </div>
                    ))}
                </div>
            </div>

            {deliveryorders[selectedItem].length > 0
                ?
                <div className="delivery-list-container">
                    {(deliveryorders[selectedItem].map((item, index) => (
                        <DeliveryCard key={index} item={item} />
                    )))}
                </div>
                :
                loading
                    ? <Loader />
                    : null
            }
        </div>
    );
};

export default Delivery;
