import React, { useState } from "react";
import "./ResetPassword.css";
import { useLocation, useNavigate } from "react-router-dom";
import { Button, Loader, TextInput } from "../../../components";
import { logo, authImage, hide, show, HomeLogo } from "../../../assets/icons";
import { CreateUUID, notifyToast, useScrollTop } from "../../../services/helpingFunc";
import { api } from "../../../network/Environment";
import { callApi, Method } from "../../../network/NetworkManger";
import { isPasswordMatch } from "../../../services/validation";
import { v4 as uuidv4 } from 'uuid';
import { useDispatch } from "react-redux";
import { SaveForgetOtpRoute } from "../../../redux/Slices/userDataSlice";

export default function ResetPassword(props) {
    useScrollTop()

    const dispatch = useDispatch()
    const location = useLocation();
    const receivedData = location.state || "No data received";
    const navigate = useNavigate();
    const [password, setPassword] = useState('')
    const [confirmPassword, setConfirmPassword] = useState('')
    const [showPassword, setShowPassword] = useState(false)
    const [showConfirmPassword, setShowConfirmPassword] = useState(false)
    const [loading, setLoading] = useState(false);

    const onHandlePress = async () => {
        if (isPasswordMatch(password, confirmPassword)) {
            const onSuccess = response => {
                dispatch(SaveForgetOtpRoute(false))
                setLoading(false);
                notifyToast(response.message);
                navigate('/signin')
            };

            const onError = error => {
                setLoading(false);
                notifyToast(error.message);
            };
            const endPoint = api.createNewPassword;
            const method = Method.PATCH;
            const bodyParams = {
                email: receivedData?.email,
                otp: receivedData?.otp,
                password,
                device: {
                    id: CreateUUID(),
                    deviceToken: CreateUUID()
                },
            };
            setLoading(true);
            callApi(method, endPoint, bodyParams, onSuccess, onError);
        }
    };

    return (
        <div className="resetPassword-auth-container">
            <Loader loading={loading} />
            <div className="resetPassword-scroll">
                <div onClick={() => navigate('/')}>
                    <img src={HomeLogo} alt='logo' className='resetPassword-image_logo_style' />
                </div>
                <div className="resetPassword-detail_view">
                    <h1>Reset Password!</h1>
                    <h2>Reset your password by entering a new one</h2>                    <TextInput onChange={(e) => setPassword(e.target.value)} onClickEye={() => setShowPassword(!showPassword)} type={!showPassword ? 'password' : 'text'} eye eyeIcon={showPassword ? show : hide} placeholder={'Enter Password'} title={'Password'} />
                    <TextInput onChange={(e) => setConfirmPassword(e.target.value)} onClickEye={() => setShowConfirmPassword(!showConfirmPassword)} type={!showConfirmPassword ? 'password' : 'text'} eye eyeIcon={showConfirmPassword ? show : hide} placeholder={'Enter Password'} title={'Confirm New Password'} />
                    <div className="resetPassword-button-view">
                        <Button onClick={() => onHandlePress()} >Continue</Button >
                    </div>
                </div>
            </div>
            <div className="resetPassword-image_sideView">
                <img src={authImage} alt='authImage' className='resetPassword-image_sideStyle' />
                <div className="image-caption">
                    <h3 className="image-title">Welcome</h3>
                    <p className="image-description">Musuwo Logistics</p>
                </div>
            </div>
        </div>
    );
}
