import React from "react";
import { Footer, Header, MainSlider, OurVision, OurValues, Reviews } from "../../../components";
import { useScrollTop } from "../../../services/helpingFunc";
import './aboutUs.css'

export default function Home() {
    useScrollTop()

    return (
        <>
            {/* ******************** Header ******************** */}
            <Header title={'About Us'} />

            {/* ******************** Main Slider ******************** (Welcome to our world, where Logistics become a seamless journey.) */}
            <MainSlider videURL={require('../../../assets/video/about_us.mp4')} mainTitle={'About Us'} title={'Welcome to Musuwo Logistics, where precision meets reliability, and logists becomes a seamless journey.'} description={'Established with a commitment to redefine the industry, we take pride in being your trusted partner for all your logistical needs.'} />

            {/* ******************** Our Vision ******************** */}
            <OurVision />

            {/* ******************** Reviews ******************** */}
            {/* <Reviews heading='About Us' /> */}

            {/* ******************** Our Values ******************** */}
            <OurValues />

            {/* ******************** Footer ******************** */}
            <Footer />
        </>
    );
}
