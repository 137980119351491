import React from 'react';
import { useNavigate } from 'react-router-dom';
import { contract } from '../../assets/icons';
import './Contract.css'; // Make sure to create this CSS file

const Contract = (props) => {
    const navigate = useNavigate()
    const handleEmailClick = (email) => {
        const gmailUrl = `https://mail.google.com/mail/?view=cm&fs=1&to=${email}`;
        window.open(gmailUrl, '_blank');
    };

    return (
        <div className="contract-container">
            <div className="contract-left-section">
                <div>
                    <h1>Contact Us Today!</h1>
                    <p> Ready to deliver excellence? Contact us today and elevate your food delivery game with our premium motorbike hire services. Send your driver’s license, proof of residence, identity/passport copy, and proof of registration with delivery platforms to <span onClick={() => handleEmailClick('admin@musuwologistics.co.za')}> admin@musuwologistics.co.za. </span> </p>
                    <div style={{ display: 'flex' }}>
                        <button onClick={() => navigate('/contactUs')} className="review-custom-button" style={{ backgroundColor: '#001F7A', color: '#fff' }}>Contact Us</button>
                    </div>
                </div>
            </div>
            <div className="contract-right-section">
                <img src={contract} alt="contract" />
            </div>
        </div>
    );
};

export default Contract;
