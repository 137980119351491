import React, { useState } from "react";
import { Footer, Header,  Courier } from "../../../components";
import { useScrollTop } from "../../../services/helpingFunc";
import './CourierScreen.css'

export default function CourierScreen() {
    useScrollTop()

    return (
        <>
            {/* ******************** Header ******************** */}
            <Header title={'Courier'} />

            {/* ******************** Courier ******************** */}
            <Courier />

            {/* ******************** Footer ******************** */}
            <Footer />
        </>
    );
}
