import React from "react";
import "./pageNotFound.css";
import { notFound } from "../../../assets/icons";

export default function PageNotFound() {
  return (
    <div className="page_not_found_container">
      <img src={notFound} alt='not_found' />
      <h1>The page you are looking for doesn't exist</h1>
    </div>
  );
}
