import React from 'react';
import { useNavigate } from 'react-router-dom';
import './MainSlider.css'; // Make sure to create this CSS file

const MainSlider = (props) => {
    const navigate = useNavigate()
    const jotformURL = 'https://form.jotform.com/233102101836037?fbclid=PAAabf4G3BUd7rrmFb_2aJRVpcWBELQHwAuXaPiiHMExbT-PCdJmBKFwVg2S0_aem_AYUKGgNxS4IsNHIwforL9DS0ZAvi64T9QYNvzI2nMcxwCa5F_Ae0wsdcvIwqFkb9qRw';

    return (
        <div className="container">
            <div className="left-section">
                <p>The Future is Now</p>
                <h1>{props?.title}</h1>
                <div>
                    <span>{props?.description}</span>
                </div>
                <button onClick={() => window.open(jotformURL, '_blank')} className="custom-button">Join Courier Network</button>
            </div>
            <div className="right-section">
                <video autoPlay muted controls>
                    <source src={props.videURL} type="video/mp4" />
                </video>
            </div>
        </div>
    );
};

export default MainSlider;
