import React, { useState } from "react";
import "./otpVerify.css";
import { useLocation, useNavigate } from "react-router-dom";
import { Button, Loader } from "../../../components";
import { logo, authImage, HomeLogo } from "../../../assets/icons";
import OTPInput from "react-otp-input";
import { CreateUUID, formatTime, notifyToast, useScrollTop } from "../../../services/helpingFunc";
import { callApi, Method } from "../../../network/NetworkManger";
import { api } from "../../../network/Environment";
import { v4 as uuidv4 } from 'uuid';
import { useDispatch } from "react-redux";
import { accessToken, refreshToken, userData } from "../../../redux/Slices/userDataSlice";
import { useRef } from "react";
import { useEffect } from "react";

export default function OtpVerify(props) {
    useScrollTop()
    const location = useLocation();
    const receivedData = location.state || "No data received";
    const navigate = useNavigate();
    const dispatch = useDispatch()

    // const [otp, setOtp] = useState('');
    const [otp, setOtp] = useState(new Array(6).fill(''));
    const inputRefs = useRef(new Array(6).fill(null));

    const [seconds, setCountDown] = React.useState(60);
    const [loading, setLoading] = useState(false);

    // *********************** Timer Start ***********************
    React.useEffect(() => {
        const interval = setInterval(() => {
            if (seconds > 0) {
                setCountDown(seconds - 1);
            }
        }, 1000);

        return () => clearInterval(interval);
    }, [seconds]);

    const reSendOtp = () => {
        setLoading(true);
        const onSuccess = response => {
            setLoading(false);
            console.log('success', 'SUCCESS response', response)
            notifyToast(response?.message)
            setCountDown(60);
        };

        const onError = error => {
            setLoading(false);
            setOtp('');
            console.log('error', 'ERROR response', error);
            notifyToast(error?.message)
        };

        const endPoint = receivedData?.key == 'auth' ? api.sendOtp : api.forgetPassword;
        const method = Method.POST;
        const bodyParams = {
            email: receivedData?.email.toLowerCase(),
        };

        callApi(method, endPoint, bodyParams, onSuccess, onError);
    };

    const OTPVerify = async () => {
        const onSuccess = response => {
            setLoading(false);
            console.log('success', 'SUCCESS response', response);
            notifyToast(response.message)
            if (response?.status == 200) {
                if (response.message == 'The email has been verified') {
                    const tokenData = {
                        token: response?.data?.token,
                        refreshToken: response?.data?.refreshToken,
                    };
                    const updatedUserData = {
                        user: response?.data?.user,
                    };
                    dispatch(accessToken(tokenData?.token))
                    dispatch(refreshToken(tokenData?.refreshToken))
                    dispatch(userData(updatedUserData))
                }
                receivedData?.key == 'auth' ? navigate('/completeProfile') : navigate('/resetPassword', { state: { email: receivedData?.email.toLowerCase(), otp: otp.join('') } })
            }
        };

        const onError = error => {
            setLoading(false);
            console.log('error', 'ERROR response', error);
            notifyToast(error.message);
        };
        const endPoint = receivedData?.key == 'auth' ? api.verifyOtp : api.verifyResetPasswordOtp;
        const method = Method.POST
        const bodyParams = {
            email: receivedData?.email.toLowerCase(),
            otp: otp.join(''),
            ...(receivedData?.key == 'auth' && {
                device: {
                    id: CreateUUID(),
                    deviceToken: CreateUUID()
                }
            })
        };
        setLoading(true);
        callApi(method, endPoint, bodyParams, onSuccess, onError);
    };

    // *********************** Custom OTP Input ***********************
    const focusNextInput = (index) => {
        if (index < otp.length - 1) {
            const nextInput = inputRefs.current[index + 1];
            if (nextInput) {
                nextInput.focus();
            }
        }
    };

    const focusPrevInput = (index) => {
        if (index > 0) {
            const prevInput = inputRefs.current[index - 1];
            if (prevInput) {
                prevInput.focus();
            }
        }
    };

    const handleChange = (element, index) => {
        if (isNaN(element.value)) return; // Only accept numeric input

        const newOtp = [...otp];
        newOtp[index] = element.value;
        setOtp(newOtp);

        if (element.value) {
            focusNextInput(index);
        }
    };

    const handleKeyDown = (event, index) => {
        if (event.key === "Backspace") {
            if (otp[index] === '') {
                focusPrevInput(index);
            } else {
                const newOtp = [...otp];
                newOtp[index] = '';
                setOtp(newOtp);
            }
        }
    };

    useEffect(() => {
        inputRefs.current[0].focus();
    }, []);

    return (
        <div className="otp-auth-container">
            <Loader loading={loading} />
            <div className="otp-scroll">
                <div onClick={() => navigate('/')}>
                    <img src={HomeLogo} alt='logo' className='otp-image_logo_style' />
                </div>

                <div className="otp-detail_view">
                    <h1>OTP Verification!</h1>
                    <h2>Enter the OTP sent to your email address to verify your account</h2>

                    {/* <OTPInput
                        inputType='tel'
                        shouldAutoFocus
                        inputStyle={{
                            borderColor: '#E2E2E2',
                            borderWidth: 1,
                            fontSize: '14px',
                            fontFamily: 'montserratSemiBold',
                            margin: '1%',
                            borderRadius: 20,
                            padding: '2%',
                            color: '#000',
                            height: '2rem',
                            width: '2.5rem',
                            background: '#FAFAFA',
                        }}
                        containerStyle={{
                            alignSelf: "center",
                        }}
                        value={otp}
                        onChange={setOtp}
                        numInputs={6}
                        renderInput={(props) => <input {...props} className="otp-input" />}
                    /> */}

                    <div style={{ display: 'flex', justifyContent: 'center' }}>
                        {otp.map((data, index) => {
                            return (
                                <input
                                    key={index}
                                    ref={el => inputRefs.current[index] = el}
                                    style={{
                                        margin: '1%',
                                        borderRadius: 20,
                                        padding: '2%',
                                        color: '#000',
                                        height: '35px',
                                        width: '45px',
                                        fontFamily: 'montserratSemiBold',
                                        fontSize: '18px',
                                        lineHeight: '26px',
                                        backgroundColor: document.activeElement === inputRefs.current[index] ? 'rgba(0, 31, 122, 0.2)' : 'rgba(250, 250, 250, 1)',
                                        textAlign: 'center',
                                        marginRight: '6px',
                                        border: '1px solid rgba(226, 226, 226, 1)',
                                        outline: 'none',
                                        borderColor: document.activeElement === inputRefs.current[index] ? 'rgba(0, 31, 122, 1)' : 'rgba(226, 226, 226, 1)'
                                    }}
                                    type="text"
                                    name={`otp-${index}`}
                                    maxLength="1"
                                    value={data}
                                    onChange={e => handleChange(e.target, index)}
                                    onKeyDown={e => handleKeyDown(e, index)}
                                    onFocus={e => e.target.select()}
                                />
                            );
                        })}
                    </div>

                    <div className="otp-resend-div">
                        <p className='otp-resent-OTP'>
                            {seconds != 0 ? formatTime(seconds - 1) : '00:00'}
                        </p>
                        <span
                            onClick={async () => {
                                if (seconds == 0) {
                                    reSendOtp()
                                }
                            }}> {'Send again'}
                        </span>
                    </div>
                    <div className="otp-button-view">
                        <Button onClick={() => OTPVerify()}>Verify</Button>
                    </div>
                </div>


            </div>
            <div className="otp-image_sideView">
                <img src={authImage} alt='authImage' className='otp-image_sideStyle' />
                <div className="image-caption">
                    <h3 className="image-title">Welcome</h3>
                    <p className="image-description">Musuwo Logistics</p>
                </div>
            </div>
        </div>
    );
}
