// ContainerComponent.jsx
import React, { useState } from 'react';
import './Profile.css'; // Import the CSS file for styling
import { useDispatch, useSelector } from 'react-redux';
import { camera, HomeLogo, logo } from '../../assets/icons';
import { api, googleApiKey } from '../../network/Environment';
import { callApi, Method } from '../../network/NetworkManger';
import { CreateUUID, notifyToast, uploadImageOnS3 } from '../../services/helpingFunc';
import Button from '../button/Button';
import TextInput from '../textInput/TextInput';
import { v4 as uuidv4 } from 'uuid';
import { accessToken, refreshToken, userData } from '../../redux/Slices/userDataSlice';
import Loader from '../loader/Loader';
import { usePlacesWidget } from "react-google-autocomplete";
import { geocodeByPlaceId, getLatLng, geocodeByAddress } from 'react-google-places-autocomplete';
import usePlacesService from "react-google-autocomplete/lib/usePlacesAutocompleteService";

const Profile = () => {
    const dispatch = useDispatch()
    const user = useSelector((data) => data.userData.userData);
    const token = useSelector((data) => data.userData);

    const [name, setName] = useState(user?.user?.name ? user?.user?.name : '')
    const [phoneNumber, setPhoneNumber] = useState(user?.user?.number ? user?.user?.number : '')
    const [location, setLocation] = useState(user?.user?.location ? user?.user?.location?.address : '');
    const [coordinated, setCoordinated] = useState({ lat: user?.user?.location ? user?.user?.location?.coordinates[1] : '', lng: user?.user?.location ? user?.user?.location?.coordinates[0] : '' });
    const [imageSrc, setImageSrc] = useState(user?.user?.image ? user?.user?.image : null);
    const [selectedImage, setSelectedImage] = useState(null);
    const [loading, setLoading] = useState(false);
    const [placeDetailsToState, savePlaceDetailsToState] = useState(null);

    const { placePredictions, getPlacePredictions, isPlacePredictionsLoading } = usePlacesService({
        apiKey: googleApiKey,
    });

    React.useEffect(() => {
        // fetch place details for the first element in placePredictions array
        if (placePredictions.length) {
            savePlaceDetailsToState(placePredictions)
        } else {
            savePlaceDetailsToState(null)
        }
    }, [placePredictions]);

    const getLatLonusingAddress = async (item) => {
        setLocation(item?.description)
        geocodeByAddress(`${item?.description}`)
            .then(results => getLatLng(results[0]))
            .then(({ lat, lng }) => {
                console.log('Successfully got latitude and longitude', { lat, lng })
                setCoordinated({ lat, lng })
            });
        savePlaceDetailsToState(null)
    }

    const { ref } = usePlacesWidget({
        apiKey: googleApiKey,
        onPlaceSelected: (place) => {
            console.log("Current places", place);
            setLocation(place?.formatted_address)
            geocodeByAddress(`${place?.address_components[0]?.long_name}, ${place?.address_components[place?.address_components.length - 1]?.long_name}`)
                .then(results => getLatLng(results[0]))
                .then(({ lat, lng }) => {
                    console.log('Successfully got latitude and longitude', { lat, lng })
                    setCoordinated({ lat, lng })
                });
        },
    });


    const handleImageUpload = (e) => {
        const file = e.target.files[0];
        setSelectedImage(file);

        if (file) {
            const reader = new FileReader();
            reader.onload = (e) => {
                setImageSrc(e.target.result);
            };
            reader.readAsDataURL(file);
        }
    };

    const onPressUploadImage = async () => {
        try {
            if (selectedImage) {
                setLoading(true);
                const imageUrl = await uploadImageOnS3(selectedImage);
                console.log('Image uploaded to S3:', imageUrl);
                if (imageUrl?.url) {
                    updateProfile(imageUrl?.url)
                }
            } else {
                setLoading(true);
                updateProfile(imageSrc)
                // notifyToast('Please Select Image');
            }
        } catch (error) {
            setLoading(false);
            console.error("Error uploading image:", error);
        }
    };

    const updateProfile = async (imagePath) => {
        const onSuccess = response => {
            setLoading(false);
            console.log('Update Profile: ', response)
            notifyToast('Profile has been Updated');
            const updatedUserData = {
                user: response?.data?.data,
                refreshToken: response?.data?.data?.deviceToken
            };

            console.log(updatedUserData)

            dispatch(userData(updatedUserData))
            // dispatch(refreshToken(updatedUserData?.refreshToken))
        };

        const onError = error => {
            setLoading(false);
            console.log('complete Profile: ', error)
            notifyToast(error?.message);
        };

        const endPoint = api.updateUser + `/${user.user?.id}`;
        const method = Method.PATCH;
        const bodyParams = {
            "name": name,
            "number": phoneNumber,
            "timeZone": Intl.DateTimeFormat().resolvedOptions().timeZone,
            "image": imagePath,
            "location": {
                "type": "Point",
                "coordinates": [
                    coordinated.lng,
                    coordinated.lat,
                ],
                "address": location,
                "description": ""
            },
            "device": {
                id: CreateUUID(),
                deviceToken: CreateUUID()
            }
        }

        callApi(method, endPoint, bodyParams, onSuccess, onError);
    }

    const deleteAccount = async () => {
        const onSuccess = response => {
            console.log('Delete Profile response: ', response)
            notifyToast('Account has been deleted successfully!');
            dispatch(userData(null))
            dispatch(accessToken(''))
            dispatch(refreshToken(''))
        };

        const onError = error => {
            console.log('Delete Profile error: ', error)
        };

        const endPoint = api.deleteUser;
        const method = Method.DELETE;
        const bodyParams = {}

        callApi(method, endPoint, bodyParams, onSuccess, onError);
    }

    return (
        <div className="profile-main-container">
            <div className="profile-main-container-centerAlign">
                <div className="profile-logo-container">
                    <img src={HomeLogo} alt="Logo" />
                </div>
                <div className="profile-logo-title" style={{ marginTop: 20 }}>Profile</div>
                <div className="profile-title">My Profile</div>
                <div className="profile-title-description">Welcome! Manage your profile here</div>
            </div>
            <div className="profile-detail_view" style={{ marginTop: '50px' }}>
                <div className='profile-circle-view' style={{ marginBottom: '30px' }}>
                    {
                        (imageSrc) && <img src={imageSrc} alt="Uploaded" className='profile-circle-view' />
                    }
                    <div className='profile-camera-icon' onClick={() => document.getElementById('fileInput').click()}>
                        <img src={camera} alt="Camera Icon" />
                    </div>
                </div>
                <input type="file" id="fileInput" accept="image/*" onChange={handleImageUpload} style={{ display: 'none' }} />

                <TextInput onChange={(e) => setName(e.target.value)} placeholder={'John Doe'} title={'Full Name'} value={name} />
                <TextInput onChange={(e) => setPhoneNumber(e.target.value)} placeholder={'+91 6266272822'} title={'Phone Number'} value={phoneNumber} />
                {/* <TextInput onChange={(e) => setLocation(e.target.value)} placeholder={'Time Square NYC, Manhattan, USA.'} value={location} /> */}
                {/* <div className="google_input">
                    <p>{'Enter Location'}</p>
                    <div className='google_input_container'>
                        <input ref={ref} value={location} onChange={(e) => setLocation(e.target.value)} placeholder='Enter Location' />
                    </div>
                </div> */}

                <div className="google_input">
                    <p>{'Enter Location'}</p>
                    <div>
                        <div className='google_input_container'>
                            <input
                                value={location}
                                placeholder='Enter Location'
                                onChange={(evt) => {
                                    getPlacePredictions({ input: evt.target.value });
                                    setLocation(evt.target.value)
                                }}
                                loading={isPlacePredictionsLoading.toString()}
                            />
                        </div>
                        <div>
                            <div style={{ position: 'absolute', width: '100%', backgroundColor: 'white', boxShadow: '0 2px 4px rgba(0,0,0,0.1)', borderRadius: '10px' }}>
                                {placeDetailsToState != null && placeDetailsToState.map((item, index) => {
                                    return (
                                        <div key={index} onClick={() => getLatLonusingAddress(item)} style={{ borderBottom: '1px solid rgba(0,0,0,0.1)', cursor: 'pointer', padding: '20px' }}>
                                            <h1 style={{ fontSize: '12px', fontFamily: 'montserratRegular', color: '#0E191E' }}>
                                                {item?.description}
                                            </h1>
                                        </div>
                                    )
                                })}
                            </div>
                        </div>
                    </div>
                </div>

                <div className="profile-button-view">
                    <Button onClick={() => onPressUploadImage()}>{loading ? 'Saving...' : 'Save'}</Button>
                    <div className='delete-account' onClick={() => deleteAccount()}>Delete Account</div>
                </div>
            </div>
        </div>
    );
};

export default Profile;
