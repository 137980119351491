import React from "react";
import { Footer, Header, Profile } from "../../../components";
import { useScrollTop } from "../../../services/helpingFunc";
import './MyProfile.css'

export default function MyProfile() {
    useScrollTop()

    return (
        <>
            {/* ******************** Header ******************** */}
            <Header title={'My Profile'}/>

            {/* ******************** Profile ******************** */}
            <Profile />

            {/* ******************** Footer ******************** */}
            <Footer />
        </>
    );
}
