// ContainerComponent.jsx
import React from 'react';
import { motorBike1, motorBike2, motorBike3, motorBike4, HomeLogo, logo } from '../../assets/icons';
import './MotorBike.css'; // Import the CSS file for styling

const arrayData = [
    { id: 1, name: 'Deflecting Windshield' },
    { id: 2, name: 'High Output USB Charger' },
    { id: 3, name: 'Factory Fitted Delivery Box Bracket' },
    { id: 4, name: 'Factory Fitted Delivery Bag' },
    { id: 5, name: 'Digital Speedo Display' },
    { id: 6, name: 'LED Lights' },
    { id: 7, name: 'Double Rear Shocks' },
]

const MotorBike = () => {
    return (
        <div className="motorBike-main-container">
            <div className="motorBike-logo-container">
                <img src={HomeLogo} alt="Logo" />
            </div>
            <div className="motorBike-logo-title">Our Services</div>
            <div className="motorBike-title">Motorbike Hire</div>
            <div className="motorBike-title-description">Join hands with Musuwo Logistics Motorbike Hire and take your food delivery service to the next level. Whether you're a restaurant, catering service, or food delivery platform, we have the right motorbike solution.</div>
            <div style={{ textAlign: 'left' }}>
                <div className="motorBike-title-below">Our Motorbikes for Food Delivery Features:</div>
                <div style={{ marginTop: 10 }}>
                    {
                        arrayData.map(item => {
                            return (
                                <div key={item.id} className="motorBike-bolitpoint">• {item.name}</div>
                            )
                        })
                    }
                </div>
            </div>

            <div className="motorBike-list-container">
                <img src={motorBike1} alt="hire1" />
                <img src={motorBike2} alt="hire2" />
                <img src={motorBike3} alt="hire3" />
                <img src={motorBike4} alt="hire4" />
            </div>
        </div>
    );
};

export default MotorBike;
