import React from "react";
import { Footer, Header, MainSlider, CustomeSupport } from "../../../components";
import { useScrollTop } from "../../../services/helpingFunc";
import './contactUs.css'

export default function Home() {
    useScrollTop()

    return (
        <>
            {/* ******************** Header ******************** */}
            <Header title={'Contact Us'} />

            {/* ******************** Main Slider ******************** */}
            <MainSlider videURL={require('../../../assets/video/contact_us.mp4')} title={'Have a question, comment, or just want to say hello?'} description={"We'd love to hear from you! Our team is here to assist you in any way we can. Choose the contact method that suits you best."} />

            {/* ******************** Contact Us ******************** */}
            <CustomeSupport />

            {/* ******************** Footer ******************** */}
            <Footer />
        </>
    );
}
