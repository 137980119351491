import React, { useState } from "react";
import "./signIn.css";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Button, Loader, TextInput } from "../../../components";
import { apple, logo, authImage, authImage2, facebook, google, hide, show, HomeLogo } from "../../../assets/icons";
import { callApi, Method } from "../../../network/NetworkManger";
import { v4 as uuidv4 } from 'uuid';
import { api } from "../../../network/Environment";
import { isLoginValid } from "../../../services/validation";
import { CreateUUID, notifyToast, useScrollTop } from "../../../services/helpingFunc";
import { accessToken, refreshToken, SaveRememberMe, userData } from "../../../redux/Slices/userDataSlice";
import { useGoogleLogin } from '@react-oauth/google';
import { useEffect } from "react";

const socialArray = [
  {
    id: 1,
    icon: google
  },
  // {
  //   id: 2,
  //   icon: apple
  // },
  // {
  //   id: 3,
  //   icon: facebook
  // }
]

export default function SignIn() {
  useScrollTop()

  const navigate = useNavigate();
  const dispatch = useDispatch()
  const [showPassword, setShowPassword] = useState(false)
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const [rememberMe, setRememberMe] = useState(false);
  const [loading, setLoading] = useState(false);

  const appLogin = async () => {
    if (email?.length === 0 && password?.length === 0) {
      notifyToast('Please Enter all field data');
    } else {
      if (isLoginValid(email, password)) {
        setLoading(true)
        const onSuccess = response => {
          setLoading(false)
          notifyToast(response.message);
          const updatedUserData = {
            user: response?.data?.user,
          };
          const tokenData = {
            token: response?.data?.token,
            refreshToken: response?.data?.refreshToken,
          };
          console.log('success', 'updatedUserData', updatedUserData);
          console.log('success', 'tokenData', tokenData);
          dispatch(userData(updatedUserData))
          dispatch(accessToken(tokenData?.token))
          dispatch(refreshToken(tokenData?.refreshToken))
          console.log('updatedUserData?.user?.isComplete: ', updatedUserData?.user?.isComplete)
          console.log('updatedUserData?.user?.isComplete typeof: ', typeof updatedUserData?.user?.isComplete)

          updatedUserData?.user?.isComplete ? navigate('/') : navigate('/completeProfile');
        };
        const onError = error => {
          console.log('error', 'ERROR', error);
          notifyToast(error?.message);
          setLoading(false)
          if (error?.errorType === 'email-not-verify') {
            navigate('/OtpVerify', { state: { email: email.toLowerCase(), key: 'auth' } });
          }
        };
        const endPoint = api.login;
        const method = Method.POST;
        const bodyParams = {
          email: email.toLowerCase(),
          password: password,
          role: 'client',
          device: {
            id: CreateUUID(),
            deviceToken: CreateUUID()
          },
        };

        callApi(method, endPoint, bodyParams, onSuccess, onError);
      }
    }
  };

  const fetchEmail = (tokenResponse) => {
    var myHeaders = new Headers();
    myHeaders.append("Authorization", `Bearer ${tokenResponse?.access_token}`);

    var requestOptions = {
      method: 'GET',
      headers: myHeaders,
      redirect: 'follow'
    };

    fetch("https://www.googleapis.com/oauth2/v3/userinfo", requestOptions)
      .then(response => response.json())
      .then(result => {
        const onSuccess = response => {
          notifyToast(response.message);
          const updatedUserData = {
            user: response?.data?.user,
          };
          const tokenData = {
            token: response?.data?.token,
            refreshToken: response?.data?.refreshToken,
          };
          console.log('success', 'updatedUserData', updatedUserData);
          console.log('success', 'tokenData', tokenData);
          dispatch(userData(updatedUserData))
          dispatch(accessToken(tokenData?.token))
          dispatch(refreshToken(tokenData?.refreshToken))
          console.log('updatedUserData?.user?.isComplete: ', updatedUserData?.user?.isComplete)
          console.log('updatedUserData?.user?.isComplete typeof: ', typeof updatedUserData?.user?.isComplete)

          updatedUserData?.user?.isComplete ? navigate('/') : navigate('/completeProfile');
        };
        const onError = error => {
          console.log('error', 'ERROR', error);
          notifyToast(error?.message);
          setLoading(false)
          if (error?.errorType === 'email-not-verify') {
            navigate('/OtpVerify', { state: { email: email.toLowerCase(), key: 'auth' } });
          }
        };
        const endPoint = api.socialLogin;
        const method = Method.POST;
        const bodyParams = {
          email: result?.email.toLowerCase(),
          role: 'client',
          device: {
            id: CreateUUID(),
            deviceToken: CreateUUID()
          },
        };

        callApi(method, endPoint, bodyParams, onSuccess, onError);

      })
      .catch(error => console.log('error', error));
  }

  const googleLogin = useGoogleLogin({
    onSuccess: tokenResponse => {
      console.log(tokenResponse)
      fetchEmail(tokenResponse)
    },
  });

  const SocialLogin = (type) => {
    if (type == 0) {
      googleLogin()
    } else {
      notifyToast('Apple Login! Coming Soon')
    }

  }

  useEffect(() => {
    dispatch(SaveRememberMe(rememberMe))
  }, [rememberMe])


  return (
    <div className="signin-auth-container">
      <Loader loading={loading} />
      <div className="signin-scroll">
        <div onClick={() => navigate('/')}>
          <img src={HomeLogo} alt='logo' className='signin-image_logo_style' />
        </div>
        <div className="signin-detail_view">
          <h1>Hello & Welcome!</h1>
          <h2>Please enter your email address and password to login.</h2>
          <TextInput value={email} onChange={(e) => setEmail(e.target.value)} placeholder={'Enter Email Address'} title={'Email'} />
          <TextInput value={password} onChange={(e) => setPassword(e.target.value)} onClickEye={() => setShowPassword(!showPassword)} type={!showPassword ? 'password' : 'text'} eye eyeIcon={showPassword ? show : hide} placeholder={'Enter Password'} title={'Password'} />
          <div className="signin-forget-password-Container" style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: "space-between", alignSelf: 'center' }}>
            <label className="signin-remember-me-label">
              <input type="checkbox" checked={rememberMe} onChange={() => setRememberMe(!rememberMe)} className='signin-checkbox' />
              Remember me
            </label>
            <div onClick={() => navigate('/forgetPassword')} className="signin-forgot_password_view">
              <h3> Forget Password? </h3>
            </div>
          </div>
          <div className="signin-button-view">
            <Button onClick={() => appLogin()}>Login</Button >
          </div>
          <div onClick={() => navigate('/signup')} className="signin-dont_have_account_view">
            <h3> Don’t have an account?  {" "} <span style={{ color: '#001F7A', textDecorationLine: "underline" }}>Sign Up</span> </h3>
          </div>
          <div className="signin-or-divider">
            <div className="signin-line"></div>
            <div className="signin-or-text">or</div>
            <div className="signin-line"></div>
          </div>
          <div className="signin-social_view">
            {socialArray.map((item, index) => {
              return (
                <div onClick={() => SocialLogin(index)} key={index}>
                  <img src={item.icon} alt='icon' />
                </div>
              )
            })}
          </div>
        </div>
      </div>
      <div className="signin-image_sideView">
        <img src={authImage} alt='authImage' className='signin-image_sideStyle' />
        <div className="image-caption">
          <h3 className="image-title">Welcome</h3>
          <p className="image-description">Musuwo Logistics</p>
        </div>
      </div>
    </div>
  );
}
