// Header.js
import React from 'react';
import { ArrowDown2, DocumentBlack, HomeLogo, logo, logout, ProfileBlack, ProfileIcon, VoucherBlack, menuIcon } from '../../assets/icons';
import './header.css'; // Import your CSS file
import { NavLink, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from 'react-redux';
import { accessToken, refreshToken, userData } from '../../redux/Slices/userDataSlice';

const Header = (props) => {
    const user = useSelector((data) => data.userData.userData);
    const dispatch = useDispatch()
    const navigate = useNavigate();
    const [isDropdownOpen, setDropdownOpen] = React.useState(false);
    const [isMobile, setIsMobile] = React.useState(false);

    const handleNavigationClick = (option) => {
        console.log(`Clicked on: ${option}`);
    };

    const toggleDropdown = () => {
        setDropdownOpen(!isDropdownOpen);
    };

    const handleToggle = () => {
        setIsMobile(!isMobile);
    };

    const logOut = () => {
        dispatch(userData(null))
        dispatch(accessToken(''))
        dispatch(refreshToken(''))

        navigate('/')
    }

    return (
        <header className="main-header">
            <div className="logo" onClick={() => navigate('/')}>
                <img src={HomeLogo} alt="Logo" />
            </div>

            <nav className="navigation">
                <ul>
                    <li><NavLink className={props.title === 'Home' && 'highlighted'} to="/" onClick={() => handleNavigationClick("Home")}>Home</NavLink></li>
                    <li><NavLink className={props.title === 'About Us' && 'highlighted'} to="/aboutUs" onClick={() => handleNavigationClick("About Us")}>About Us</NavLink></li>
                    <li><NavLink className={props.title === 'Courier Network' && 'highlighted'} to="/courierNetwork" onClick={() => handleNavigationClick("Courier Network")}>Courier Network</NavLink></li>
                    <li><NavLink className={props.title === 'Contact Us' && 'highlighted'} to="/contactUs" onClick={() => handleNavigationClick("Contact Us")}>Contact Us</NavLink></li>  </ul>
            </nav>

            <div className="user-options">
                {
                    user
                        ?
                        <div className="profile-icon-container">
                            <div className="profile-icon" onClick={toggleDropdown}>
                                <img className='profile-img' src={user?.user?.image ? user?.user?.image : ProfileIcon} alt="Profile" />
                                <img className='arrowDown' src={ArrowDown2} alt="dropdown-arrow" />
                            </div>
                            {isDropdownOpen && (
                                <>
                                    <div>
                                        <div className='open-dropDown-user'>
                                            <div className="dropdown-menu">
                                                <div onClick={() => navigate('/')} className="dropdown-option">
                                                    <span className={props.title === 'Home' && 'dropdown-optionHighlighted'}>Home</span>
                                                </div>
                                                <div onClick={() => navigate('/aboutUs')} className="dropdown-option">
                                                    <span className={props.title === 'About Us' && 'dropdown-optionHighlighted'}>About Us</span>
                                                </div>
                                                <div onClick={() => navigate('/courierNetwork')} className="dropdown-option">
                                                    <span className={props.title === 'Courier Network' && 'dropdown-optionHighlighted'}>Courier Network</span>
                                                </div>
                                                <div onClick={() => navigate('/contactUs')} className="dropdown-option">
                                                    <span className={props.title === 'Contact Us' && 'dropdown-optionHighlighted'}>Contact Us</span>
                                                </div>

                                                {/* <div style={{ backgroundColor: '#cccccc', height: 1 }} /> */}

                                                <div onClick={() => navigate('/myProfile')} className="dropdown-option">
                                                    {/* <img src={ProfileBlack} alt="My Profile" /> */}
                                                    <span className={props.title === 'My Profile' && 'dropdown-optionHighlighted'}>My Profile</span>
                                                </div>
                                                <div onClick={() => navigate('/pastDeliveries')} className="dropdown-option">
                                                    {/* <img src={DocumentBlack} alt="My Bookings" /> */}
                                                    <span className={props.title === 'Past Deliveries' && 'dropdown-optionHighlighted'}>Past Deliveries</span>
                                                </div>
                                                <div className="dropdown-option" onClick={() => logOut()}>
                                                    {/* <img src={logout} alt="Logout" /> */}
                                                    <span>Logout</span>
                                                </div>
                                            </div>
                                        </div>

                                        <div className='close-dropDown-user'>
                                            <div className="dropdown-menu">
                                                <div onClick={() => navigate('/myProfile')} className="dropdown-option">
                                                    <img src={ProfileBlack} alt="My Profile" />
                                                    <span className={props.title === 'My Profile' && 'dropdown-optionHighlighted'}>My Profile</span>
                                                </div>
                                                <div onClick={() => navigate('/pastDeliveries')} className="dropdown-option">
                                                    <img src={DocumentBlack} alt="My Bookings" />
                                                    <span className={props.title === 'Past Deliveries' && 'dropdown-optionHighlighted'}>Past Deliveries</span>
                                                </div>
                                                {/* <div onClick={() => navigate('/vouchers')} className="dropdown-option">
                                                <img src={VoucherBlack} alt="Logout" />
                                                <span>Vouchers</span>
                                                </div> */}
                                                <div className="dropdown-option" onClick={() => logOut()}>
                                                    <img src={logout} alt="Logout" />
                                                    <span>Logout</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </>
                            )}
                        </div>
                        :
                        <>
                            <NavLink to="/signin" className="login">Login</NavLink>
                            <NavLink to="/signup" className="register">Sign Up</NavLink>

                            <div className="toggle-button" onClick={handleToggle}>
                                <img src={menuIcon} alt="dropdown-arrow" />
                            </div>
                            <div className="profile-icon-container" style={{ marginTop: '5%' }}>
                                {
                                    isMobile && (
                                        <div className="dropdown-menu">
                                            <div onClick={() => navigate('/')} className="dropdown-option">
                                                <span className={props.title === 'Home' && 'dropdown-optionHighlighted'}>Home</span>
                                            </div>
                                            <div onClick={() => navigate('/aboutUs')} className="dropdown-option">
                                                <span className={props.title === 'About Us' && 'dropdown-optionHighlighted'}>About Us</span>
                                            </div>
                                            <div onClick={() => navigate('/courierNetwork')} className="dropdown-option">
                                                <span className={props.title === 'Courier Network' && 'dropdown-optionHighlighted'}>Courier Network</span>
                                            </div>
                                            <div onClick={() => navigate('/contactUs')} className="dropdown-option">
                                                <span className={props.title === 'Contact Us' && 'dropdown-optionHighlighted'}>Contact Us</span>
                                            </div>
                                            <div onClick={() => navigate('/signin')} className="dropdown-option">
                                                <span className={props.title === 'Login' && 'dropdown-optionHighlighted'}>Login</span>
                                            </div>
                                            <div onClick={() => navigate('/signup')} className="dropdown-option">
                                                <span className={props.title === 'Register' && 'dropdown-optionHighlighted'}>Register</span>
                                            </div>
                                        </div>
                                    )
                                }
                            </div>
                        </>
                }
            </div>
        </header>
    );
}

export default Header;
