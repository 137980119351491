import React from "react";
import { Footer, Header, MainSlider } from "../../../components";
import NetworkBenefits from "../../../components/benefits/Benefits";
import { useScrollTop } from "../../../services/helpingFunc";
import './CourierNetwork.css'

export default function CourierNetwork() {
    useScrollTop()

    return (
        <>
            {/* ******************** Header ******************** */}
            <Header title={'Courier Network'} />

            {/* ******************** Main Slider ******************** */}
            <MainSlider videURL={require('../../../assets/video/courier_network.mp4')} title={'Unleashing the Power Of Our Courier Network'} description={'Our Member Network is the heartbeat of our operations, fostering relationships, enabling seamless communication, and enhancing the efficiency of our logistics solutions.'} />

            {/* ******************** Main Slider ******************** */}
            <NetworkBenefits />

            {/* ******************** Footer ******************** */}
            <Footer />
        </>
    );
}
